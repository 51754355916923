import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import splash from './splash'
import Axios from 'axios';
import alerta from './alerta'
import cerrarSesion from './cerrar'

function Blog(params) {
  const [bl_titulo, set_bl_titulo] = useState("");
  const [bl_texto, set_bl_texto] = useState("");
  const [bl_fecha, set_bl_fecha] = useState("");
  const [bl_fecha_vence, set_bl_fecha_vence] = useState("");
  const [bl_tipo, set_bl_tipo] = useState("");
  const [bl_foto, set_bl_foto] = useState("");
  const [bl_v, set_bl_v] = useState(0);
  const [bl_video, set_bl_video] = useState("");
  const [bl_video_tipo, set_bl_video_tipo] = useState("");
  const [bl_s, set_bl_s] = useState(0);
  const [bl_seccion, set_bl_seccion] = useState("");

  const [documento, setDocumento] = useState("");

  const misDatos = () => {
    const filtro = params.modal.arreglo

    set_bl_titulo(filtro.bl_titulo)
    let contenidon = filtro.bl_texto.replaceAll('<br />', '')
    set_bl_texto(contenidon)
    set_bl_fecha(filtro.bl_fecha)
    set_bl_fecha_vence(filtro.bl_fecha_vence)
    set_bl_tipo(filtro.bl_tipo)
    set_bl_foto(filtro.bl_foto)
    set_bl_v(filtro.bl_v)
    set_bl_video(filtro.bl_video)
    set_bl_video_tipo(filtro.bl_video_tipo)
    set_bl_s(filtro.bl_s)
    set_bl_seccion(filtro.bl_seccion)
  }

  let initialized = false
  useEffect(() => {
    if(!initialized){
      initialized = true //eslint-disable-line
      splash()
    }

    let hooy = new Date();
    set_bl_fecha(`${hooy.getFullYear()}-${(hooy.getMonth()+1)<10 ? '0' : ''}${hooy.getMonth()+1}-${(hooy.getDate())<10 ? '0' : ''}${hooy.getDate()}`)
  }, []);

  useEffect(() => {
    if(params.modal.arreglo){
      misDatos()
    }
  }, [params.modal.arreglo]); //eslint-disable-line

  return (
    <div>
      <Form>
        <div className="flex_botones">
          <div id={bl_s===1 ? 'boton_marca' : null} onClick={() => {
            if(bl_s===1){
              set_bl_s(0)
            }else{
              set_bl_s(1)
            }
          }}>
            Destacar
          </div>
        </div>
        <Form.Group>
          <Form.Label>*Tipo:</Form.Label>
          <div className="flex_botones">
            <div id={bl_seccion==='Eventos' ? 'boton_marca' : null} onClick={() => set_bl_seccion('Eventos')}>Eventos</div>
            <div id={bl_seccion==='Noticias' ? 'boton_marca' : null} onClick={() => set_bl_seccion('Noticias')}>Noticias</div>
            <div id={bl_seccion==='Artículos' ? 'boton_marca' : null} onClick={() => set_bl_seccion('Artículos')}>Artículos</div>
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label>*Título:</Form.Label>
          <Form.Control type="text" placeholder="Título de la publicación" value={bl_titulo} onChange={(event) => {set_bl_titulo(event.target.value)}} required/>
        </Form.Group>
        <Form.Group>
          <Form.Label>*Fecha:</Form.Label>
          <Form.Control type="date" placeholder="aaaa-mm-dd" value={bl_fecha} onChange={(event) => {set_bl_fecha(event.target.value)}} required/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Vigencia:</Form.Label>
          <Form.Control type="date" placeholder="aaaa-mm-dd" value={bl_fecha_vence} onChange={(event) => {set_bl_fecha_vence(event.target.value)}}/>
        </Form.Group>
        {
          bl_seccion==='Artículos' ?
            <Form.Group>
              <Form.Label>*Categoría:</Form.Label>
              <Form.Select onChange={(event) => set_bl_tipo(event.target.value)} required>
                <option selected={bl_tipo==="" ? true : false}></option>
                {
                  window._my_blog_categorias.map((item, i) =>
                    <option key={i} value={item.id} selected={bl_tipo===item.id ? true : false}>{item.nombre}</option>
                  )
                }
              </Form.Select>
            </Form.Group>
          :
            null
        }
        <Form.Group>
          <Form.Label>Descripción:</Form.Label>
          <Form.Control as="textarea" rows={3} value={bl_texto} onChange={(event) => {set_bl_texto(event.target.value)}}/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Imagen o fotografía: <h19>("900px ancho" x "500px alto" o proporcional)</h19></Form.Label>
          <Form.Control onChange={(e) =>{
            if(!e.target.files[0]) {
              alerta('error','Un campo requerido está vacio');
            }else{
              let condicion = e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg"
              let leyenda = 'PNG o JPG'
              if(condicion){
                window.H5_loading.show();

                const config = {
                  headers: {
                      'content-type': 'multipart/form-data'
                  }
                }

                const data = new FormData();
                data.append('que', 'blog');
                //data.append('que', 1);
                data.append('file', e.target.files[0]);
                data.append('_USUARIO', localStorage.usuario);
                data.append('_ROL', localStorage._ROL);
                data.append('_Activa', localStorage._Activa);

                Axios.post(window.ws+'wsDocumento.php', data, config)
                .then(response => {
                  if(response.data.elError===3){
                    cerrarSesion(1)
                  }else if(response.data.elError===1){
                    alerta('success',response.data.mensaje)
                    setDocumento(response.data.documento)
                  }else{
                    alerta('error',response.data.mensaje)
                  }
                })
                .catch(error => {
                  //alerta('error',''+error);
                  console.log(error)
                })
                .then(function() {
                  window.H5_loading.hide();
                })
              }else{
                alerta('error','El archivo debe estar en formato '+leyenda);
              }
            }
          }} id="file-upload" type="file" accept=".png, .jpg"/>
          {
            documento || bl_foto ?
              <a href={`${window.ws}documentos/blog/${documento ? `${localStorage.usuario}_TEMPORAL.${documento}` : `${params.modal.id}.${bl_foto}?v=${bl_v}`}`} target="_new" className="icono_archivo">
                <img src={params.modal.id>0 ? "../img/foto.png" : "img/foto.png"} title="Ver imagen" alt="Ver imagen" className="hvr-push"/>
              </a>
            :
              null
          }
        </Form.Group>
        <Form.Group>
          <Form.Label>Incluir video:</Form.Label>
          <div className="flex_botones">
            <div id={bl_video_tipo==='YouTube' ? 'boton_marca' : null} onClick={() => {
              set_bl_video('')
              if(bl_video_tipo==='YouTube'){
                set_bl_video_tipo('')
              }else{
                set_bl_video_tipo('YouTube')
              } 
            }}>YouTube</div>
            <div id={bl_video_tipo==='MP4' ? 'boton_marca' : null} onClick={() => {
              set_bl_video('')
              if(bl_video_tipo==='MP4'){
                set_bl_video_tipo('')
              }else{
                set_bl_video_tipo('MP4')
              } 
            }}>MP4</div>
          </div>
        </Form.Group>
        {
          bl_video_tipo ?
            <Form.Group>
              <Form.Label>*{bl_video_tipo==='YouTube' ? "Enlace de YouTube" : "Enlace del video"}:</Form.Label>
              <Form.Control type="text" placeholder={bl_video_tipo==='YouTube' ? "ej. https://youtu.be/" : "ej. http://ejemplo.com/mi_video.mp4"} value={bl_video} onChange={(event) => {set_bl_video(event.target.value)}} required/>
            </Form.Group>
          :
            null
        }
        <div className="espacio_input"/>
        <div className="campos">
          Campos obligatorios (*)
        </div>
        <div id="cl"></div>
        <div className="fin_botones">
          <Button type="submit" variant="success" onClick={(event) => {
            event.preventDefault()
            if(!bl_titulo || !bl_fecha || (bl_seccion==='Artículos' && !bl_tipo) || !bl_seccion || (bl_video_tipo && !bl_video)){
              alerta('error','Un campo requerido esta vacío');
            }else{
              window.H5_loading.show();
              var pasar = new URLSearchParams();
              pasar.append('_USUARIO', localStorage.usuario);
              pasar.append('_ROL', localStorage._ROL);
              pasar.append('_Activa', localStorage._Activa);

              pasar.append('que', 'registrar');
              pasar.append('bl_id', params.modal.id);
              pasar.append('bl_seccion', bl_seccion);
              pasar.append('bl_titulo', bl_titulo);
              pasar.append('bl_texto', bl_texto);
              pasar.append('bl_fecha', bl_fecha);
              pasar.append('bl_fecha_vence', bl_fecha_vence);
              pasar.append('bl_tipo', bl_tipo);
              pasar.append('bl_foto', bl_foto);
              pasar.append('bl_video', bl_video);
              pasar.append('bl_video_tipo', bl_video_tipo);
              pasar.append('bl_s', bl_s);
              pasar.append('documento', documento);

              Axios.post(`${window.ws}wsBlog.php`, pasar)
                .then(response => {
                  if(response.data.elError===3){
                    cerrarSesion(1)
                  }else if(response.data.elError===1){
                    alerta('success',''+response.data.mensaje);
                    if(params.modal.id>0){
                      params.listaTodas()
                    }else{
                      params.listaTodas(0)
                    }
                    params.setModal({
                      ver: false,
                      id: 0,
                    })
                  }else{
                    alerta('error',''+response.data.mensaje);
                  }
                })
                .catch(error => {
                  console.log(error)
                })
                .then(function() {
                  window.H5_loading.hide();
                })
            }
          }}>
              {params.modal.id>0 ? "Editar" : "Registrar"}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default Blog;