import { useEffect, useState/*, useCallback*/ } from 'react';
import Axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import splash from './ultilidades/splash'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const alerta = (tipo,titulo) =>{
  Swal.fire({
    type: tipo,
    title: titulo
  });
}

function MisEventos(params) {
  const [modalServicios, setModalServicios] = useState(false);
  const [servicio, setServicio] = useState("");

  let initialized = false
  useEffect(() => {
    
    if(!initialized){
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div>
      {
        params.arreglo.slice(params.inicial, params.mostrar).length>0 ?
          <div className="servicios margin_servicios">
            {
              params.arreglo.slice(params.inicial, params.mostrar).map((item, i) => {
                let texto = item.descripcion
                if(texto.length>150){
                  texto = texto.substring(0,150)+"..."
                }
                let miFecha = new Date(`${item.fecha.split(" ")[0]}T00:00`)
                return(
                  <div className="my_servicio" data-aos="fade-down" key={i}>
                    <div className="box_servicio">
                      <div className="caja_servicio">
                        <div className="servicio_relative">
                          <img src={item.foto} title={item.titulo} alt={item.titulo}/>  
                          <div className="servicio_contrato">
                            <div onClick={() => {
                              setModalServicios(true)
                              setServicio(item.id)
                            }}>
                              Ver más
                            </div>
                          </div>
                        </div>
                        <div className="servicio_info">
                          <center>
                            <h15>{item.titulo}</h15>
                            <br/>
                            {`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}
                          </center>
                          <div dangerouslySetInnerHTML={{ __html: texto }} className="texto_servicios"/>
                          {
                            item.lista.length>0 ?
                              <div>
                                <b>Temas principales:</b>
                                <ul className="lista_servicios">
                                  {
                                    item.lista.slice(0, 3).map((lista, n) =>
                                      <li key={n}>{lista}</li>
                                    )
                                  }
                                  {
                                    item.lista.length>3 ?
                                      <li>y más...</li>
                                    :
                                      null
                                  }
                                </ul>
                              </div>
                            :
                              null
                          }
                          <div className="servicio_mas" onClick={() => {
                            setModalServicios(true)
                            setServicio(item.id)
                          }}>
                            Ver más detalles
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        :
          null
      }
      <Modal
        show={modalServicios}
        onHide={() => {
          setModalServicios(false)
          servicio(0)
        }}
        size="lg"
        centered
      >
        {
          servicio==='aviso' ?
            null
          :
            params.arreglo.filter(p => p.id===servicio).map((item, i) => {
              let miFecha = new Date(`${item.fecha.split(" ")[0]}T00:00`)
              return(
                <>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h15>{item.titulo}</h15>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <table className="tabla_organismos">
                      <tr>
                        <td colspan="2"><img src={item.foto} title={item.titulo} alt={item.titulo} className="img_organismos"/></td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <div dangerouslySetInnerHTML={{ __html: item.descripcion }} className="texto_libro"/>
                        </td>
                      </tr>
                      {
                        item.dirigido ?
                          <tr>
                            <td><b>Dirigido a:</b></td>
                            <td>{item.dirigido}</td>
                          </tr>
                        :
                          null
                      }
                      <tr>
                        <td><b>Fecha:</b></td>
                        <td>{`${window.dia[miFecha.getDay()]} ${miFecha.getDate()} de ${window.meses[miFecha.getMonth()]}, ${miFecha.getFullYear()}`}</td>
                      </tr>
                      {
                        item.horario ?
                          <tr>
                            <td><b>Horario:</b></td>
                            <td>{item.horario}</td>
                          </tr>
                        :
                          null
                      }
                      {
                        item.lista.length>0 ?
                          <tr>
                            <td><b>Temas principales:</b></td>
                            <td>
                              <ul className="lista_servicios">
                                {
                                  item.lista.map((lista, n) =>
                                    <li key={n}>{lista}</li>
                                  )
                                }
                              </ul>
                            </td>
                          </tr>
                        :
                          null
                      }
                      {
                        item.direccion ?
                          <tr>
                            <td><b>Dirección</b></td>
                            <td>{item.direccion}</td>
                          </tr>
                        :
                          null
                      }
                      {
                        item.ubicacion ?
                          <tr>
                            <td><b>Ubicación</b></td>
                            <td>
                              <div className="web_modal_organismos">
                                <a href={item.ubicacion} target="new">
                                  <img src="img/ubicacion.svg" alt="Ubicación"/>
                                  Ver ubicación
                                </a>
                              </div>
                            </td>
                          </tr>
                        :
                          null
                      }
                      {
                        item.web ?
                          <tr>
                            <td><b>Sitio web</b></td>
                            <td>
                              <div className="web_modal_organismos">
                                <a href={item.web} target="new">
                                  <img src="img/www.svg" alt="www"/>
                                  Visitar el sitio
                                </a>
                              </div>
                            </td>
                          </tr>
                        :
                          null
                      }
                      {
                        item.convocatoria ?
                          <tr>
                            <td><b>Convocatoria</b></td>
                            <td>
                              <div className="web_modal_organismos">
                                <a href={item.convocatoria} target="new">
                                  <img src="img/infografia.svg" alt="Convocatoria"/>
                                  Ver convocatoria
                                </a>
                              </div>
                            </td>
                          </tr>
                        :
                          null
                      }
                    </table>
                  </Modal.Body>
                </>
              )
            })
        }
      </Modal>
    </div>
  );
}

export default MisEventos;
