import { useEffect, useState/*, useCallback*/ } from 'react';
import Button from 'react-bootstrap/Button';
import { Element, scroller } from 'react-scroll';
import Axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import splash from './ultilidades/splash'
import MisEventos from './MisEventos'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const alerta = (tipo,titulo) =>{
  Swal.fire({
    type: tipo,
    title: titulo
  });
}

function Eventos() {
  const [inicial, setInicial] = useState(0)
  const [mostrar, setMostrar] = useState(window._my_cantidad_eventos)
  //const cantidad = 6

  let initialized = false
  useEffect(() => {
    
    if(!initialized){
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div>
      <div className="fondo_titulos">
        <div className="max_width texto_titulo">
          <br/>Eventos
        </div>
      </div>
      <div className="fondo_servicios">
        <div className="max_width padding_width">
          <center>
            <Element name="recorrer"></Element>
            <h11>Todos los eventos</h11>
            <h14>EVENTOS REALIZADOS POR EL CLÚSTER MINERO DE HIDALGO</h14>
          </center>
          <MisEventos inicial={inicial} mostrar={mostrar} arreglo={window._my_servicios}/>
          {/*
          <div className="flex_siguiente">
            <div>{inicial===0 ? null : <Button variant="dark" onClick={() => {
              setMostrar(mostrar-cantidad)
              setInicial(inicial-cantidad)
              scroller.scrollTo('recorrer', {
                duration: 100,
                delay: 0,
                smooth: 'easeInOutQuart'
              })
            }}>🡨</Button>}</div>
            <div>{mostrar>=window._my_servicios.length ? null : <Button variant="dark" onClick={() => {
              setMostrar(mostrar+cantidad)
              setInicial(inicial+cantidad)
              scroller.scrollTo('recorrer', {
                duration: 100,
                delay: 0,
                smooth: 'easeInOutQuart'
              })
            }}>🡪</Button>}</div>
          </div>
          */}
          <div className="pa_flex_siguiente">
              <div className="fleex_siguiente">
                <div>
                  {
                    inicial===0 ?
                      null
                    :
                      <button className="siguiente con_mar" onClick={() =>{
                        scroller.scrollTo('recorrer', {
                          duration: 100,
                          smooth: true,
                          spy:true,
                        })
                        //let numeros = cantidad-window._my_cantidad_eventos
                        //setCantidad(numeros)
                        //listaTodas(numeros)
                        setMostrar(mostrar-window._my_cantidad_eventos)
                        setInicial(inicial-window._my_cantidad_eventos)
                      }}>
                        Anterior
                      </button>
                  }
                </div>
                <div>
                  {
                    (inicial+window._my_cantidad_eventos)>=window._my_servicios.length ?
                      null
                    :
                      <button className="siguiente" onClick={() =>{
                        scroller.scrollTo('recorrer', {
                          duration: 100,
                          smooth: true,
                          spy:true,
                        })
                        //let numeros = cantidad+window._my_cantidad_eventos
                        //setCantidad(numeros)
                        //listaTodas(numeros)
                        setMostrar(mostrar+window._my_cantidad_eventos)
                        setInicial(inicial+window._my_cantidad_eventos)
                      }}>
                        Siguiente
                      </button>
                  }
                </div>  
              </div>
              <div className="num_pag">Página {(Math.ceil(inicial/window._my_cantidad_eventos)+1)} de {Math.ceil(window._my_servicios.length/window._my_cantidad_eventos)}</div>
            </div>

        </div>
      </div>
    </div>
  );
}

export default Eventos;
