import { useEffect, useState/*, useCallback*/ } from 'react';
import { StickyContainer, Sticky } from 'react-sticky';
import Axios from 'axios';
import { useParams, useHistory } from 'react-router-dom'
import splash from './ultilidades/splash'
import alerta from './ultilidades/alerta'
import Infografias from './ultilidades/Infografias'
import VerBlog from './ultilidades/Blog'
import Swal from 'sweetalert2'
import cerrarSesion from './ultilidades/cerrar'
import Modal from 'react-bootstrap/Modal';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Noticias() {
  let parametro = useParams();
  let history = useHistory();
  const [modalBlog, setModalBlog] = useState({
    ver: false,
    id: 0,
  })

  const [lista_blog, set_lista_blog] = useState([])
  const listaTodas = () => {
    window.H5_loading.show();
    var pasar = new URLSearchParams();
    pasar.append('_CLUSTER', 'clusterminerodehidalgo');

    //pasar.append('inicial', cantidad);
    pasar.append('que', 'blog');
    pasar.append('c_id', parametro.id);

    Axios.post(`${window.ws}wsSelect.php`, pasar)
      .then(response => {
        set_lista_blog(response.data.lista_array)
        if(response.data.mensaje){
          console.log(response.data.mensaje)
          history.push("/");
        }
      })
      .catch(error => {
        console.log(error)
        //alert(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  let initialized = false
  useEffect(() => {
    listaTodas()
    if(!initialized){
      initialized = true //eslint-disable-line
      splash()
    }
    if(isNaN(parametro.id)){
      history.push("/");
    }
  }, []);

  return (
    <div>
      <div className="fondo_titulos">
        <div className="max_width texto_titulo">
          <br/>Noticias
        </div>
      </div>
      <div className="fondo_gris">
        <div className="max_width padding_width">
          {
            lista_blog.length>0 ?
              <div>
                <StickyContainer className="flex_paginacion">
                  <div className="paginacion">
                    {
                      lista_blog.map((item, i) => {
                        let foto = ""
                        if(item.bl_foto){
                          foto = `${window.ws}documentos/blog/${item.bl_id}.${item.bl_foto}?v=${item.bl_v}`
                        }else{
                          foto = "../img/fondo.svg"
                        }
                        let miFecha = new Date(`${item.bl_fecha.split(" ")[0]}T00:00`)
                        return(
                          <div key={i}>
                            <div className="titulos_intranet con_blog">
                              {item.bl_titulo}
                            </div>
                            {item.bl_tipo===12 && item.bl_video && item.bl_video_tipo ? null : <img className="blog_img" src={foto} title={item.bl_titulo} alt={item.bl_titulo}/>}
                            <div className="blog_fecha">
                              {
                                item.bl_tipo>0 ?
                                  <div>
                                    <img src="../img/b_categoria.svg" alt="Categoría"/>
                                    {window._my_blog_categorias.filter(p => p.id===item.bl_tipo)[0].nombre}
                                  </div>
                                :
                                  null
                              }
                              <div>
                                <img src="../img/b_fecha.svg" alt="Fecha"/>
                                {`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}
                              </div>
                            </div>
                            {item.bl_texto ? <div dangerouslySetInnerHTML={{ __html: item.bl_texto }} className="noticia_texto"/> : null}
                            {
                              item.bl_video && item.bl_video_tipo ?
                                item.bl_video_tipo==='YouTube' ?
                                  item.bl_video.slice(0, 31)==='https://www.youtube.com/watch?v' ?
                                    <iframe className="videos_noticias" src={`https://www.youtube.com/embed/${item.bl_video.split('watch?v')[1]}`} title={item.bl_titulo} allowfullscreen></iframe>
                                  : item.bl_video.slice(0, 17)==='https://youtu.be/' ?
                                    <iframe className="videos_noticias" src={`https://www.youtube.com/embed/${item.bl_video.split('/')[3]}`} title={item.bl_titulo} allowfullscreen></iframe>
                                  :
                                    <div className="sin_validar top_10">El enlace del video tiene un error</div>
                                :
                                  item.bl_video.slice(-4)==='.mp4' ?
                                    <video src={item.bl_video} className="videos_noticias_mp4" controls type="video/mp4" poster={foto}/>
                                  :
                                    <div className="sin_validar top_10">El enlace del video tiene un error</div>
                              :
                                null
                            }
                            {
                              localStorage._ROL==='ADMIN_CLUSTER' ?
                                <div className="boton_blog">
                                  <div onClick={() =>{
                                    setModalBlog({
                                      ver: true,
                                      id: parametro.id,
                                      arreglo: item,
                                    })
                                  }}>
                                    Editar
                                  </div>
                                  <div className="boton_margen" onClick={() =>{
                                    Swal.fire({
                                      title: '¿Deseas eliminar la publicación?',
                                      //text: "You won't be able to revert this!",
                                      type: 'warning',
                                      showCancelButton: true,
                                      confirmButtonColor: '#3085d6',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: 'Sí, eliminar!'
                                    }).then((result) => {
                                      if(result.value){
                                        window.H5_loading.show();
                                        var pasar = new URLSearchParams();
                                        pasar.append('_USUARIO', localStorage.usuario);
                                        pasar.append('_ROL', localStorage._ROL);
                                        pasar.append('_Activa', localStorage._Activa);
                                        
                                        pasar.append('que', 'borrar');
                                        pasar.append('id', parametro.id);
                                        Axios.post(`${window.ws}wsBlog.php`, pasar)
                                          .then(response => {
                                            if(response.data.elError===3){
                                              cerrarSesion(1)
                                            }else if(response.data.elError===1){
                                              alerta('success',''+response.data.mensaje);
                                              history.push("/blog");
                                            }else{
                                              alerta('error',''+response.data.mensaje);
                                            }
                                          })
                                          .catch(error => {
                                            console.log(error)
                                          })
                                          .then(function() {
                                            window.H5_loading.hide();
                                          })
                                      }
                                    })
                                  }}>
                                    Eliminar
                                  </div>
                                  {/*
                                  <div className="boton_margen" onClick={() =>{
                                    let decir = "validar"
                                    if(item.bl_validar>0){
                                      decir = "invalidar"
                                    }
                                    Swal.fire({
                                      title: `¿Deseas ${decir} la publicación?`,
                                      //text: "You won't be able to revert this!",
                                      type: 'warning',
                                      showCancelButton: true,
                                      confirmButtonColor: '#3085d6',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: `Sí, ${decir}!`
                                    }).then((result) => {
                                      if(result.value){
                                        window.H5_loading.show();
                                        var pasar = new URLSearchParams();
                                        pasar.append('_USUARIO', localStorage.usuario);
                                        pasar.append('_ROL', localStorage._ROL);
                                        pasar.append('_Activa', localStorage._Activa);
                                        
                                        pasar.append('que', 'validar');
                                        pasar.append('id', parametro.id);
                                        Axios.post(`${window.ws}wsBlog.php`, pasar)
                                          .then(response => {
                                            if(response.data.elError===3){
                                              cerrarSesion(1)
                                            }else if(response.data.elError===1){
                                              alerta('success',''+response.data.mensaje);
                                              listaTodas()
                                            }else{
                                              alerta('error',''+response.data.mensaje);
                                            }
                                          })
                                          .catch(error => {
                                            console.log(error)
                                          })
                                          .then(function() {
                                            window.H5_loading.hide();
                                          })
                                      }
                                    })
                                  }}>
                                    {item.bl_validar>0 ? "Invalidar" : "Validar"}
                                  </div>
                                  */}
                                </div>
                              :
                                null
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className="publicidad">
                    <Sticky>
                      {({
                        style,
                        isSticky,
                        wasSticky,
                        distanceFromTop,
                        distanceFromBottom,
                        calculatedHeight
                      }) => (
                        <header style={style} className="secciones_publicidad">
                          <Infografias />
                        </header>
                      )}
                    </Sticky>
                  </div>
                </StickyContainer>
                <div className="secciones_publicidad quita_sec_pub">
                  <Infografias />
                </div>
              </div>
            :
             null
          }
        </div>
      </div>
      <Modal
        show={modalBlog.ver}
        size="lg"
        className="modal_registro"
        centered
        onHide={() => 
          setModalBlog({
            ver: false,
            id: 0,
          })
        }
        //style={{zIndex: index>0 ? 3 : null}}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Editar publicación
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VerBlog modal={modalBlog} setModal={setModalBlog} listaTodas={listaTodas}/>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Noticias;
