import { useEffect, useState/*, useCallback*/ } from 'react';
import Axios from 'axios';
//import Swal from 'sweetalert2'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import alerta from './ultilidades/alerta'
import limpiarVariables from './ultilidades/limpiar'
import cerrarSesion from './ultilidades/cerrar'

function Cuenta(params) {
  const [usuario, set_usuario] = useState("");
  //const [contrasena, set_contrasena] = useState("");
  //const [modal, setModal] = useState(false);
  const [modalAqui, setModalAqui] = useState(false)

  const [pass, set_pass] = useState("");
  const [passw, set_passw] = useState("");
  const [passa, set_passa] = useState("");

  //const [us_editar, set_us_editar] = useState("");
  const [us_status, set_us_status] = useState(0);
  const [us_nombre, set_us_nombre] = useState("");
  const [us_nombre_dos, set_us_nombre_dos] = useState("");
  const [us_cargo, set_us_cargo] = useState("")
  const [us_nombre_tres, set_us_nombre_tres] = useState("");
  const [us_curp, set_us_curp] = useState("");
  const [us_tel, set_us_tel] = useState("");
  const [us_cel, set_us_cel] = useState("");
  const [us_correo, set_us_correo] = useState("");
  const [us_sector, set_us_sector] = useState("");
  const [us_web, set_us_web] = useState("");
  const [us_servicios, set_us_servicios] = useState("");

  const [us_especifique, set_us_especifique] = useState("");
  const [us_giro, set_us_giro] = useState("");
  const [us_tipo, set_us_tipo] = useState("");
  const [us_estado, set_us_estado] = useState("");
  const [us_municipio, set_us_municipio] = useState("");
  const [us_domicilio, set_us_domicilio] = useState("");
  const [us_cp, set_us_cp] = useState("");

  const [us_rfc, set_us_rfc] = useState("");
  const [us_rfc_dos, set_us_rfc_dos] = useState("");
  const [us_razon, set_us_razon] = useState("");
  const [aviso, set_aviso] = useState(false)

  const misDatos = () => {
    window.H5_loading.show();
    var pasar = new URLSearchParams();
    pasar.append('_USUARIO', localStorage.usuario);
    pasar.append('_ROL', localStorage._ROL);
    pasar.append('_Activa', localStorage._Activa);
    pasar.append('_CLUSTER', 'clusterminerodehidalgo');

    //pasar.append('que', 'consultar');
    pasar.append('que', params.cual);
    if(params.cual==='editar' && params.id>0){
      pasar.append('us_id', params.id);
    }

    Axios.post(`${window.ws}wsRegistro.php`, pasar)
      .then(response => { 
        if(response.data.elError===3){
          cerrarSesion(1)
        }else if(response.data.elError===1){
          //set_us_editar(response.data.us_usuario)
          if(params.cual==='editar' && localStorage._ROL==='ADMIN_CLUSTER'){
            set_us_status(response.data.us_status)
          }
          set_usuario(response.data.us_usuario)
          set_us_nombre(response.data.us_nombre)
          set_us_nombre_dos(response.data.us_nombre_dos)
          set_us_cargo(response.data.us_cargo)
          set_us_nombre_tres(response.data.us_nombre_tres)
          set_us_curp(response.data.us_curp)
          set_us_tel(response.data.us_tel)
          set_us_cel(response.data.us_cel)
          set_us_correo(response.data.us_correo)
          set_us_sector(response.data.us_sector)
          set_us_web(response.data.us_web)
          set_us_servicios(response.data.us_servicios)
          set_us_especifique(response.data.us_especifique)
          set_us_giro(response.data.us_giro)
          set_us_tipo(response.data.us_tipo)
          set_us_estado(response.data.us_estado)
          set_us_municipio(response.data.us_municipio)
          set_us_domicilio(response.data.us_domicilio)
          set_us_cp(response.data.us_cp.toString())
          set_us_rfc(response.data.us_rfc)
          set_us_rfc_dos(response.data.us_rfc_dos)
          set_us_razon(response.data.us_razon)
        }else{
          alerta('error', ''+response.data.mensaje)
          console.log(response.data.mensaje)
        }
      })
      .catch(error => {
        //alerta('error', ''+error)
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  let initialized = false
  useEffect(() => {
    
    if(!initialized){
      initialized = true //eslint-disable-line
      
      window.H5_loading.show();
      setTimeout(() => {
        window.H5_loading.hide();
      }, 1000)

      if(params.cual==="micuenta" || (params.cual==="editar" && params.id>0)){
        misDatos()
      }
    }
  }, []);

  const limpiar = () => {
    //set_us_editar("")
    set_us_status("")
    set_us_nombre("")
    set_us_nombre_dos("")
    set_us_cargo("")
    set_us_nombre_tres("")
    set_us_curp("")
    set_us_tel("")
    set_us_cel("")
    set_us_correo("")
    set_us_web("")
    set_us_servicios("")

    set_us_especifique("")
    set_us_giro("")
    set_us_tipo("")
    set_us_estado("")
    set_us_municipio("")
    set_us_domicilio("")
    set_us_cp("")

    set_us_rfc("")
    set_us_rfc_dos("")
    set_us_razon("")
    set_aviso(false)
  }

  return (
    <div>
      <Form>
        <div className="texto_formulario">
          Completa los siguientes campos.
        </div>
        <Form.Group>
          <Form.Label>*Correo electrónico:</Form.Label>
          <Form.Control type="text" value={usuario} onChange={(event) => {set_usuario(event.target.value)}} required/>
        </Form.Group>
        <Form.Group>
          <Form.Label>{params.cual==="login" || params.cual==="nuevo" ? "*" : null}Contraseña:</Form.Label>
          {
            params.cual==="micuenta" ?
              <div>
                <Form.Control type="password" placeholder="Contraseña actual" value={passa} onChange={(event) => {set_passa(event.target.value)}}/>
                <div className="espacio_input"/>
              </div>
            :
              null
          }
          <Form.Control type="password" placeholder="Contraseña nueva" value={pass} onChange={(event) => {set_pass(event.target.value)}} required={params.cual==="login" || params.cual==="nuevo" ? true : false}/>
          <div className="espacio_input"/>
          <Form.Control type="password" placeholder="Confirmar contraseña nueva" value={passw} onChange={(event) => {set_passw(event.target.value)}} required={params.cual==="login" || params.cual==="nuevo" ? true : false}/>
        </Form.Group>
        {
          params.cual==='editar' && localStorage._ROL==='ADMIN_CLUSTER' ?
            <Form.Group>
              <Form.Label>*Estado de la cuenta:</Form.Label>
              <Form.Select onChange={(event) => set_us_status(event.target.value)} required>
                <option selected={us_status==="" ? true : false}></option>
                <option selected={us_status===0 ? true : false} value={0}>Nuevo</option>
                <option selected={us_status===2 ? true : false} value={2}>Inactivo</option>
                <option selected={us_status===3 ? true : false} value={3}>Activo</option>
                <option selected={us_status===1 ? true : false} value={1}>Baja</option>
              </Form.Select>
            </Form.Group>
          :
            null
        }
        <div className="espacio_input"/>
        <div className="texto_formulario">
          Selecciona una opción.
        </div>
        <Form.Group>
          <Form.Label>*Sector:</Form.Label>
          <div className="flex_botones">
            <div id={us_sector==='Minera' ? 'boton_marca' : null} onClick={() => {
              if(params.cual==='login' || localStorage._ROL==="ADMIN_CLUSTER"){
                set_us_sector('Minera')
              }
              if(params.cual==='login' || params.cual==='nuevo'){
                limpiar()
              }
            }}>Empresa Minera</div>
            <div id={us_sector==='Proveedora' ? 'boton_marca' : null} onClick={() => {
              if(params.cual==='login' || localStorage._ROL==="ADMIN_CLUSTER"){
                set_us_sector('Proveedora')
              }
              if(params.cual==='login' || params.cual==='nuevo'){
                limpiar()
              }
            }}>Empresa Proveedora</div>
            <div id={us_sector==='Cámara' ? 'boton_marca' : null} onClick={() => {
              if(params.cual==='login' || localStorage._ROL==="ADMIN_CLUSTER"){
                set_us_sector('Cámara')
              }
              if(params.cual==='login' || params.cual==='nuevo'){
                limpiar()
              }
            }}>Cámara o Sociedad</div>
            <div id={us_sector==='Academia' ? 'boton_marca' : null} onClick={() => {
              if(params.cual==='login' || localStorage._ROL==="ADMIN_CLUSTER"){
                set_us_sector('Academia')
              }
              if(params.cual==='login' || params.cual==='nuevo'){
                limpiar()
              }
            }}>Academia</div>
            <div id={us_sector==='Gobierno' ? 'boton_marca' : null} onClick={() => {
              if(params.cual==='login' || localStorage._ROL==="ADMIN_CLUSTER"){
                set_us_sector('Gobierno')
              }
              if(params.cual==='login' || params.cual==='nuevo'){
                limpiar()
              }
            }}>Gobierno</div>
            <div id={us_sector==='Otro' ? 'boton_marca' : null} onClick={() => {
              if(params.cual==='login' || localStorage._ROL==="ADMIN_CLUSTER"){
                set_us_sector('Otro')
              }
              if(params.cual==='login' || params.cual==='nuevo'){
                limpiar()
              }
            }}>Otro</div>
          </div>
        </Form.Group>
        {
          us_sector ?
            <div className="borde_formulario">
              {
                us_sector==='Otro' ?
                  <Form.Group>
                    <Form.Label>*Especifique:</Form.Label>
                    <Form.Control type="text" placeholder="" value={us_especifique} onChange={(event) => {set_us_especifique(event.target.value)}} required/>
                  </Form.Group>
                :
                  null
              }
              {
                us_sector==='Minera' || us_sector==='Proveedora' ? 
                  <div className="titulos_formulario m_titulo_for">Datos de la empresa</div>
                : us_sector==='Academia' ?
                  <div className="titulos_formulario m_titulo_for">Datos de la institución</div>
                : us_sector==='Cámara' ?
                  <div className="titulos_formulario m_titulo_for">Datos de la cámara o sociedad</div>
                : us_sector==='Gobierno' ?
                  <div className="titulos_formulario m_titulo_for">Datos de la dependencia</div>
                : us_sector==='Otro' ?
                  <div className="titulos_formulario m_titulo_for">Datos de contacto</div>
                :
                  null
              }
              {
                us_sector==='Minera' || us_sector==='Proveedora' ?
                  <div>
                    <Form.Group>
                      <Form.Label>*Tipo:</Form.Label>
                      <div className="flex_botones">
                        <div id={us_tipo==='Física' ? 'boton_marca' : null} onClick={() => set_us_tipo('Física')}>Física</div>
                        <div id={us_tipo==='Moral' ? 'boton_marca' : null} onClick={() => set_us_tipo('Moral')}>Moral</div>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>*Giro:</Form.Label>
                      <div className="flex_botones">
                        <div id={us_giro==='Metálico' ? 'boton_marca' : null} onClick={() => set_us_giro('Metálico')}>Metálico</div>
                        <div id={us_giro==='No metálico' ? 'boton_marca' : null} onClick={() => set_us_giro('No metálico')}>No metálico</div>
                        <div id={us_giro==='Otro' ? 'boton_marca' : null} onClick={() => set_us_giro('Otro')}>Otro</div>
                      </div>
                    </Form.Group>
                    {
                      us_giro==='Otro' ?
                        <Form.Group>
                          <Form.Label>*Especifique:</Form.Label>
                          <Form.Control type="text" value={us_especifique} onChange={(event) => {set_us_especifique(event.target.value)}} required/>
                        </Form.Group>
                      :
                        null
                    }
                  </div>
                : us_sector==='Gobierno' ?
                  <Form.Group>
                    <Form.Label>*Tipo:</Form.Label>
                    <div className="flex_botones">
                      <div id={us_tipo==='Federal' ? 'boton_marca' : null} onClick={() => set_us_tipo('Federal')}>Federal</div>
                      <div id={us_tipo==='Estatal' ? 'boton_marca' : null} onClick={() => set_us_tipo('Estatal')}>Estatal</div>
                      <div id={us_tipo==='Municipal' ? 'boton_marca' : null} onClick={() => set_us_tipo('Municipal')}>Municipal</div>
                    </div>
                  </Form.Group>
                :
                  null
              }
              {
                us_tipo==='Moral' ?
                  <div>
                    <Form.Group>
                      <Form.Label>*Razón social:</Form.Label>
                      <Form.Control type="text" value={us_razon} onChange={(event) => {set_us_razon(event.target.value)}} required/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>*Registro Federal de Contribuyentes (RFC):</Form.Label>
                      <Form.Control type="text" value={us_rfc_dos} onChange={(event) => {set_us_rfc_dos(event.target.value)}} required/>
                    </Form.Group>
                  </div>
                : us_tipo==='Física' ?
                  <Form.Group>
                    <Form.Label>*Clave Única de Registro de Población (CURP):</Form.Label>
                    <Form.Control type="text" value={us_curp} onChange={(event) => {set_us_curp(event.target.value)}} required/>
                  </Form.Group>
                :
                  null
              }
              <Form.Group>
                <Form.Label>*Nombre:</Form.Label>
                <Form.Control type="text" placeholder={us_sector==='Gobierno' ? "Nombre de la dependencia" : us_sector==='Cámara' | us_sector==='Academia' ? "Nombre de la institución" : "Nombre completo"} value={us_nombre} onChange={(event) => {set_us_nombre(event.target.value)}} required/>
              </Form.Group>
              {
                us_sector==='Academia' || us_sector==='Cámara' ?
                  <div>
                    <Form.Group>
                      <Form.Label>*Nombre {us_sector==='Academia' ? "del rector o director" : "del presidente"}:</Form.Label>
                      <Form.Control type="text" placeholder="Nombre completo" value={us_nombre_tres} onChange={(event) => {set_us_nombre_tres(event.target.value)}} required/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>*Nombre del contacto:</Form.Label>
                      <Form.Control type="text" placeholder="Nombre completo" value={us_nombre_dos} onChange={(event) => {set_us_nombre_dos(event.target.value)}} required/>
                    </Form.Group>
                  </div>
                :
                  null
              }
              {
                us_sector==='Gobierno' || us_sector==='Minera' || us_sector==='Proveedora' ?
                  <Form.Group>
                    <Form.Label>{us_sector==='Gobierno' ? null : "*"}Registro Federal de Contribuyentes (RFC):</Form.Label>
                    <Form.Control type="text" value={us_rfc} onChange={(event) => {set_us_rfc(event.target.value)}} required={us_sector==='Gobierno' ? false : true}/>
                  </Form.Group>
                :
                  null
              }
              <Form.Group>
                <Form.Label>{us_sector==="Otro" ? null : "*"}Teléfono fijo:</Form.Label>
                <Form.Control type="number" placeholder="Solo número 10 digitos" minlength="10" min="0" maxlength="10" value={us_tel} onChange={(event) => {set_us_tel(event.target.value)}} required={us_sector==="Otro" ? false : true}/>
              </Form.Group>
              <Form.Group>
                <Form.Label>{us_sector==="Otro" ? "*" : null}Teléfono móvil:</Form.Label>
                <Form.Control type="number" placeholder="Solo número 10 digitos" minlength="10" min="0" maxlength="10" value={us_cel} onChange={(event) => {set_us_cel(event.target.value)}} required={us_sector==="Otro" ? true : false}/>
              </Form.Group>
              <Form.Group>
                <Form.Label>*Correo electrónico:</Form.Label>
                <Form.Control type="text" value={us_correo} onChange={(event) => {set_us_correo(event.target.value)}} required/>
              </Form.Group>
              <Form.Group>
                <Form.Label>*Estado:</Form.Label>
                <Form.Select onChange={(event) => set_us_estado(event.target.value)} required>
                  <option selected={us_estado==="" ? true : false}></option>
                  {
                    window._my_estado.map((item, i) =>
                      <option key={i} selected={us_estado===item.estado ? true : false}>{item.estado}</option>
                    )
                  }
                </Form.Select>
              </Form.Group>
              {
                us_estado ?
                  <Form.Group>
                    <Form.Label>*Municipio:</Form.Label>
                    <Form.Select onChange={(event) => set_us_municipio(event.target.value)} required>
                      <option selected={us_municipio==="" ? true : false}></option>
                      {
                        window._my_estado.filter(p => p.estado===us_estado)[0].municipios.map((item, i) =>
                          <option key={i} selected={us_municipio===item ? true : false}>{item}</option>
                        )
                      }
                    </Form.Select>
                  </Form.Group>
                :
                  null
              }
              <Form.Group>
                <Form.Label>*Código postal:</Form.Label>
                <Form.Control type="number" placeholder="Solo número 5 digitos" minlength="5" maxlength="5" value={us_cp} onChange={(event) => {set_us_cp(event.target.value)}} required/>
              </Form.Group>
              <Form.Group>
                <Form.Label>*Dirección:</Form.Label>
                <Form.Control type="text" placeholder="Calle, número, colonia o fraccionamiento" value={us_domicilio} onChange={(event) => {set_us_domicilio(event.target.value)}} required/>
              </Form.Group>
              {
                us_sector!=='Otro' ?
                  <Form.Group>
                    <Form.Label>Sitio web:</Form.Label>
                    <Form.Control type="text" value={us_web} onChange={(event) => {set_us_web(event.target.value)}}/>
                  </Form.Group>
                :
                  null
              }
              {
                us_sector==='Gobierno' ?
                  <div>
                    <div className="titulos_formulario m_titulo_for">
                      Datos del contacto
                    </div>
                    <Form.Group>
                      <Form.Label>*Nombre:</Form.Label>
                      <Form.Control type="text" value={us_nombre_dos} onChange={(event) => {set_us_nombre_dos(event.target.value)}} required/>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>*Cargo:</Form.Label>
                      <Form.Control type="text" value={us_cargo} onChange={(event) => {set_us_cargo(event.target.value)}} required/>
                    </Form.Group>
                  </div>
                :
                  null
              }
              <Form.Group>
                <Form.Label>*Servicios que ofrece:</Form.Label>
                <Form.Control as="textarea" placeholder="" rows={3} value={us_servicios} onChange={(event) => {set_us_servicios(event.target.value)}} required/>
              </Form.Group>
            </div>
          :
            null
        }
        {
          !params.id || params.id===0 ? 
            <Form.Group>
              <div className="espacio_input"/>
              <Form.Label>*Para continuar debes leer y aceptar los términos y condiciones:</Form.Label>
              <div className="ver_aviso" onClick={() => {
                setModalAqui(true)
                params.setIndex(1)
              }}>Leer términos y condiciones</div>
              <Form.Check
                type="switch"
                id="custom-switch"
                label={aviso===true ? "Acepto" : "No acepto"}
                onClick={() => {set_aviso(!aviso)}}
              />
            </Form.Group>
          :
            null
        }
        <div className="espacio_input"/>
        <div className="campos">
          Campos obligatorios (*)
        </div>
        <div id="cl"></div>
        <div className="fin_botones">
          <Button type="submit" variant="success" onClick={(event) => {
            event.preventDefault()
            if(!us_nombre | !usuario | !us_correo | !us_servicios | ((params.cual==='login' | params.cual==='nuevo') && (!pass | !passw)) | !us_sector | ((us_sector==='Minera' | us_sector==='Proveedora') && ((us_tipo==='Física' && !us_curp) | (us_giro==='Moral' && (!us_razon | !us_rfc_dos)) | (us_giro==='Otro' && !us_especifique) | !us_tipo | !us_giro | !us_giro | !us_rfc)) | ((us_sector==='Academia' | us_sector==='Cámara') && (!us_nombre_tres | !us_nombre_dos)) | (us_sector==='Gobierno' && (!us_tipo | !us_nombre_dos | !us_cargo)) | (us_sector==='Otro' && (!us_especifique | !us_cel)) | (us_sector!=='Otro' && !us_tel) | !us_estado | !us_municipio | !us_cp | !us_domicilio | (params.cual==='editar' && params.id===0)){
              alerta('error','Un campo requerido esta vacío');
            }else if(aviso===false && (!params.id | params.id===0)){
              alerta('error','Debes aceptar los términos y condciones para registrarte');
            }else if(pass!==passw){
              alerta('error','Las contraseñas deben ser iguales');
            }else{
              window.H5_loading.show();
              var pasar = new URLSearchParams();
              if(localStorage.usuario && params.cual==='micuenta'){
                pasar.append('_Activa', limpiarVariables(localStorage._Activa));
                pasar.append('us_editar', limpiarVariables(localStorage.usuario));
              }else if(params.cual==='editar' && params.id>0){
                pasar.append('_ROL', localStorage._ROL);
                pasar.append('us_id', parseInt(params.id));
                pasar.append('us_status', parseInt(us_status));
              }
              pasar.append('cual', limpiarVariables(params.cual));
              //pasar.append('us_editar', limpiarVariables(us_editar));
              pasar.append('usuario', limpiarVariables(usuario));
              pasar.append('pass', limpiarVariables(pass));
              pasar.append('passw', limpiarVariables(passw));
              pasar.append('passa', limpiarVariables(passa));
              pasar.append('us_nombre', limpiarVariables(us_nombre));
              pasar.append('us_nombre_dos', limpiarVariables(us_nombre_dos));
              pasar.append('us_cargo', limpiarVariables(us_cargo));
              pasar.append('us_nombre_tres', limpiarVariables(us_nombre_tres));
              pasar.append('us_curp', limpiarVariables(us_curp));
              pasar.append('us_tel', limpiarVariables(us_tel));
              pasar.append('us_cel', limpiarVariables(us_cel));
              pasar.append('us_correo', limpiarVariables(us_correo));
              pasar.append('us_sector', limpiarVariables(us_sector));
              pasar.append('us_web', limpiarVariables(us_web));
              pasar.append('us_servicios', limpiarVariables(us_servicios));

              pasar.append('us_especifique', limpiarVariables(us_especifique));
              pasar.append('us_giro', limpiarVariables(us_giro));
              pasar.append('us_tipo', limpiarVariables(us_tipo));
              pasar.append('us_estado', limpiarVariables(us_estado));
              pasar.append('us_municipio', limpiarVariables(us_municipio));
              pasar.append('us_domicilio', limpiarVariables(us_domicilio));
              pasar.append('us_cp', limpiarVariables(us_cp));

              pasar.append('us_rfc', limpiarVariables(us_rfc));
              pasar.append('us_rfc_dos', limpiarVariables(us_rfc_dos));
              pasar.append('us_razon', limpiarVariables(us_razon));
              pasar.append('_CLUSTER', 'clusterminerodehidalgo');
              Axios.post(`${window.ws}wsRegistro.php`, pasar)
                .then(response => {
                  if(response.data.elError===1){
                    alerta('success',''+response.data.mensaje);
                    if(params.cual==='editar'){
                      params.set_id(0)
                      params.susDatos('editar')
                    }
                    if(params.modalLogin && (params.cual==="login" || params.cual==="nuevo" || params.cual==="editar")){
                      /*
                      params.setModalLogin({
                        ver: false,
                        tipo: '',
                      })
                      */
                    }else{
                      set_pass("")
                      set_passa("")
                      set_passw("")
                    }
                    if(params.cual==='micuenta'){
                      misDatos()
                      if(localStorage.usuario!==limpiarVariables(usuario)){
                        localStorage.setItem('usuario', limpiarVariables(usuario))
                        window.location.href = window.rutas+"cuenta";
                      }
                    }
                  }else{
                    alerta('error',''+response.data.mensaje);
                  }
                })
                .catch(error => {
                  console.log(error)
                })
                .then(function() {
                  window.H5_loading.hide();
                })
            }
          }}>
            Enviar
          </Button>
        </div>
      </Form>
      <Modal
        show={modalAqui}
        size="lg"
        className="modal_registro"
        centered
        onHide={() => {
          setModalAqui(false)
          params.setIndex(0)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Términos y condiciones
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: window.terminos_condiciones }}/>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Cuenta;
