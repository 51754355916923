import { useState/*, useEffect, useCallback*/ } from 'react';
import { Link as Ir } from 'react-scroll';
import Accordion from 'react-bootstrap/Accordion';

import Modal from 'react-bootstrap/Modal';

function Pie() {
  const [modalServicios, setModalServicios] = useState(false);
  const [servicio, setServicio] = useState("");

  return (
    <div>
      <div className="pie">
        <div className="max_width padding_pie">
          <div className="mi_pie alinear">
            <div className="flex_start alinear">
              <Ir to="inicio" className="subir">➤</Ir>
              {window.nombre} © {window.ano}
            </div>
            <div>
              <div className="flex_end alinear">
                <a href={`tel:${window._my_telefono}`} target="new" className="flex_tel alinear">
                  <img src={`${window.rutas}img/con_1.svg`} title="Teléfono" alt="Teléfono"/>
                  {window.telefono}
                </a>
                <a href={`mailto:${window.correo}`} target="new" className="flex_tel alinear">
                  <img src={`${window.rutas}img/con_3.svg`} title="Correo electrónico" alt="Correo electrónico"/>
                  {window.correo}
                </a>
              </div>
              <div className="para_aviso">
                <div onClick={() => {
                  setModalServicios(true)
                  setServicio("preguntas")
                }}>Preguntas frecuentes</div>
                |
                <div onClick={() => {
                  setModalServicios(true)
                  setServicio("aviso")
                }}>Aviso de privacidad</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {
        window.whats ?
          <a href={window.whats} target="new" className="whats">
            <img src={`${window.rutas}img/r_whats-01.svg`} title="WhatsApp" alt="WhatsApp"/>
          </a>
        :
          null
      }
      <Modal
        show={modalServicios}
        onHide={() => {
          setModalServicios(false)
          servicio(0)
        }}
        size="lg"
        centered
      >
        {
          servicio==='aviso' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Aviso de privacidad</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="at_j">
                  <b>Propósito por el cual se recaban sus datos personales y protección de los mismos</b>
                  <br/><br/>
                  La Dirección de Minería de la Secretaría de Desarrollo Económico, es la responsable del uso y protección de los datos personales que nos proporcione,
                  <br/><br/>
                  Los datos personales que recabamos y utilizamos de usted, son necesarios para la <b>alimentación de la plataforma alojada en la URL siguiente: https://clusterminerodehidalgo.mx</b> y los utilizaremos para las siguientes finalidades: <b>Generar un directorio de integrantes del clúster y Mantener un contacto directo vía llamada telefónica, correo, mensaje de texto o aplicación WhatsApp;</b> y que son necesarios  para identificar a los integrantes del sector minero pertenecientes al clúster.
                  <br/><br/>
                  Se le informa que sus datos personales serán compartidos con las siguientes dependencias y áreas y no se requiere el consentimiento de conformidad con el artículo 98 fracción II de la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados para el Estado de Hidalgo:
                  <ul className="lista_avi">
                    <li>
                      <b>Dependencias Federales, Estatales y Municipales.</b> Vinculación para la regulación normativa, fomento, promoción, asesorías técnicas al sector minero.
                    </li>
                    <li>
                      <b>Empresas Mineras y Proveedoras.</b> Vinculaciones para la prestación de un servicio.
                    </li>
                    <li>
                      <b>Cámaras y/o Asociaciones.</b> Identificación de los miembros del Clúster para gestionar la pertenecía a un grupo.
                    </li>
                  </ul>
                  <br/>
                  En caso de que no desee que sus datos personales sean tratados para las finalidades y transferencia descritas, usted puede manifestarlo en el correo electrónico <b>clusterminero@hidalgo.gob.mx</b>.
                  <br/><br/>
                  Si usted no manifiesta su negativa para dichas transferencias, entenderemos que nos lo ha otorgado.
                  <br/><br/>
                  Para mayor información acerca del tratamiento y de los derechos que puede hacer valer, usted puede acceder al aviso de privacidad integral a través de: <b>https://clusterminerodehidalgo.mx</b> , en la sección de Servicios / Avisos de Privacidad.
                </div>
              </Modal.Body>
            </>
          : servicio==='preguntas' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Preguntas frecuentes</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Accordion defaultActiveKey="0">
                  {
                    window._my_preguntas.map((item, i) =>
                      <Accordion.Item eventKey={i.toString()}>
                        <Accordion.Header>{item.id}. {item.pregunta}</Accordion.Header>
                        <Accordion.Body>
                          {item.respuesta}
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  }
                </Accordion>
              </Modal.Body>
            </>
          :
            null
        }
      </Modal>
    </div>
  );
}

export default Pie;
