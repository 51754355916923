import { useEffect } from 'react';
import splash from './ultilidades/splash'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Nosotros() {
  let initialized = false
  useEffect(() => {
    
    if(!initialized){
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div>
      <div className="fondo_titulos">
        <div className="max_width texto_titulo">
          <br/>Nosotros
        </div>
      </div>
      <div className="fondo_servicios">
        <div className="max_width padding_width">
          <div className="flex_between alinear">
            <div className="nosotros_texto" data-aos="fade-right">
              <h11>Conócenos</h11>
              <h12>Objetivo</h12>
              <div className="justificar">
                Promover el desarrollo del sector minero, mediante estrategias de colaboración que impulsen la innovación, desarrollo tecnológico, cuidado al medio ambiente, seguridad laboral y cadenas de valor, entre las empresas mineras, proveedores, instituciones académicas, cámaras y el gobierno.
              </div>
              <br/>
              <h12>Misión</h12>
              <div className="justificar">
                Implementar prácticas responsables que maximicen el valor económico, social y ambiental, impulsando el progreso tecnológico, la innovación y el crecimiento inclusivo del sector minero.
              </div>
              <br/>
              <h12>Visión</h12>
              <div className="justificar">
                Ser un referente nacional e internacional en la industria minera, reconocido por nuestra identidad, capacidad de integrar tecnología de punta, prácticas sostenibles y colaboración efectiva entre los diversos actores del sector.
              </div>
              {/*
              <h12>¿Porque Nosotros?</h12>
              <ul className="lista">
                <li>Más de {window.hoy.getFullYear()-2010-1} años de experiencia</li>
                <li>Puntualidad y profesionalismo</li>
                <li>Equipo de audio profesional</li>
                <li>Apegados 100% al concepto del evento</li>
                <li>Amplio repertorio musical (cualquier género o época)</li>
                <li>Sin mixes pregrabados o con “efectitos”</li>
              </ul>
              */}
            </div>
            <div className="nosotros_img" data-aos="fade-left">
              <div className="box_imagen">
                <img src="img/nosotros.jpg" title={window.nombre} alt={window.nombre}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fondo_gris">
        <div className="max_width padding_width">
          <center>
            <h14>Comisiones de trabajo</h14>
          </center>
          <div className="justificar">
            El Clúster Minero de Hidalgo es la plataforma que permite la vinculación efectiva y la comunicación asertiva que fundamenta el compromiso de las mineras por impulsar el desarrollo económico en el Estado. Para lograr estos objetivos el CMH cuenta con <b>3 comisiones de trabajo</b> que atañen a las principales necesidades del sector que son las siguientes:
          </div>
          <div className="servicios margin_servicios">
            {
              window._my_comisiones.map((item, i) => {
                let texto = item.objetivo
                if(texto.length>150){
                  //texto = texto.substring(0,150)+"..."
                }
                return(
                  <div className="my_servicio" data-aos="fade-down" key={i}>
                    <div className="box_servicio">
                      <div className="caja_servicio">
                        <div className="servicio_info">
                          <center>
                            <h15>{item.titulo}</h15>
                          </center>
                          <div dangerouslySetInnerHTML={{ __html: texto }} className="texto_servicios"/>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nosotros;
