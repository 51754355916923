import { useEffect, useState/*, useCallback*/ } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2'
import splash from './ultilidades/splash'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const alerta = (tipo,titulo) =>{
  Swal.fire({
    type: tipo,
    title: titulo
  });
}

function Contacto() {
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");

  const [datos, setDatos] = useState(false);
  const [fecha, setFecha] = useState("");
  const [hora, setHora] = useState("");
  const [duracion, setDuracion] = useState("");
  const [estado, setEstado] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [cp, setCP] = useState("");
  const [tipo, setTipo] = useState("");
  const [personas, setPersonas] = useState("");

  let initialized = false
  useEffect(() => {
    
    if(!initialized){
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div>
      <div className="fondo_titulos">
        <div className="max_width texto_titulo">
          <br/>Contacto
        </div>
      </div>
      <div className="fondo_contacto">
        <div className="max_width padding_width">
          <center>
            <h11>Contacto</h11>
            <h12>Escríbenos un mensaje</h12>
          </center>
          <form className="form_contacto" data-aos="fade-down">
            <div className="campos_formulario">
              <input type="text" name="nombre" onChange={(e) => setNombre(e.target.value)} placeholder="Nombre:" value={nombre} required/>
              <input type="text" name="correo" onChange={(e) => setCorreo(e.target.value)} placeholder="Correo:" value={correo} required/>
              <input type="text" name="telefono" onChange={(e) => setTelefono(e.target.value)} placeholder="Teléfono:" value={telefono} required/>
            </div>
            <textarea name="mensaje" id="area" onChange={(e) => setMensaje(e.target.value)} placeholder="Mensaje:" value={mensaje} required/>
            <center><button onClick={(e) => {
              e.preventDefault();
              if(nombre===''){
                alerta('error','Ingresa tu nombre completo');
              }else if(correo===''){
                alerta('error','Ingresa tu correo electrónico');
              }else if(window.validarMail(correo)===false){
                alerta('error','Ingrese un correo electrónico valido');
              }else if(mensaje===''){
                alerta('error','Escribe un mensaje');
              }else{
                window.H5_loading.show();
                var params = new URLSearchParams();
                params.append('nombre', nombre);
                params.append('correo', correo);
                params.append('mensaje', mensaje);
                params.append('telefono', telefono);
                
                Axios.post(`${window.envia}`, params)
                  .then(response => {
                    if(response.data.elError===1){
                      alerta('success',response.data.mensaje)
                      setNombre("");
                      setCorreo("");
                      setTelefono("");
                      setMensaje("");
                    }else{
                      alerta('error',response.data.mensaje)
                    }
                  })
                  .catch(error => {
                    //window.alerta('error','LOLERO'+error);
                    console.log(error)
                  })
                  .then(function() {
                    window.H5_loading.hide();
                  })
              }
            }}>Enviar mensaje</button></center>
          </form>
        </div>
      </div>
      <iframe src={window._my_ubicacion}
        width="100%" 
        height="300" 
        frameborder="0"
        title="Ubicación"
        style={{border:0}} 
        allowfullscreen>
      </iframe>
    </div>
  );
}

export default Contacto;
