import { useEffect, useState/*, useCallback*/ } from 'react';
import { Link/*, useHistory*/ } from 'react-router-dom'
//import Axios from 'axios';
import { Element/*, Link as Ir*/ } from 'react-scroll';
import ModalLogin from './ultilidades/ModalLogin'
import cerrarSesion from './ultilidades/cerrar'

//import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { miMenu, } from './../redux/menuRedux'
/*
import Swal from 'sweetalert2'

const alerta = (tipo,titulo) =>{
  Swal.fire({
    type: tipo,
    title: titulo
  });
}
*/

function Inicio() {
  //const menuRedux = useSelector((state) => state.menu.value)
  const dispatch = useDispatch()

  const [modalLogin, setModalLogin] = useState({
    ver: false,
    tipo: '',
  });
  //const [background, setBackground] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [verMenu, setVerMenu] = useState('no_ver_menu');

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      //setBackground(true)
      setScroll(window.scrollY)
    });

    return () => {
      /*
      if(window.location.href.substr(0, 5)==='http:' && window.location.href!=='http://localhost:3000/'){
        window.location.href = window.rutas;
      }
      */
      //window.removeEventListener("scroll", (e) => setBackground(false));
    };
  }, [/*background*/]);

  //let suma = 0

  const Drop = () => {
    return(
      <div class={window.screen.width>750 ? "dropdown-content" : "t_c"}>
        {
          localStorage._ROL==='ADMIN_CLUSTER' ?
            <>
              <Link to="../usuarios" className="mi_enlace">Usuarios</Link>
              {/*<Link to="../publicar" className="mi_enlace">Publicar</Link>*/}
            </>
          :
            null
        }
        <Link to="../cuenta" className="mi_enlace">Mi cuenta</Link>
        <div className="mi_enlace" onClick={() => cerrarSesion()}>Salir</div>
      </div>
    )
  }

  return (
    <div>
      <div className="icono_menu" onClick={() => setVerMenu('ver_menu')}><img src={`${window.rutas}img/menu.svg`} title="Menú" alt="Menú"/></div>
      <div className="cerrar_menu" id={verMenu} onClick={() => setVerMenu('no_ver_menu')}></div>
      <div className="menu" id={scroll>50 && window.screen.width>750 ? 'menu' : window.screen.width>750 ? 'menuTop' : verMenu}>
        <div className="max_width_menu">
          <div className="menu_flex">
            <img src={scroll>50 && window.screen.width>750 ? `${window.rutas}img/logo_minero.svg` : `${window.rutas}img/logo_minero_b.svg`} title={window.nombre} alt={window.nombre} className="logo_minero_img"/>
            <img src={`${window.rutas}img/logo_minero.svg`} title={window.nombre} alt={window.nombre} className="logo_minero_img_dos"/>
            <div className="enlaces">
              <Link to="../inicio" className="mi_enlace">Inicio</Link>
              <Link to="../nosotros" className="mi_enlace">Nosotros</Link> 
              <Link to="../noticias" className="mi_enlace" onClick={() => dispatch(miMenu('noticias'))}>Eventos</Link>
              <Link to="../servicios" className="mi_enlace">Servicios</Link>
              <Link to="../contacto" className="mi_enlace">Contacto</Link>
              <div className="raya_quita">|</div>
              {
                localStorage._ROL ?
                  window.screen.width>750 ?
                    <div className="dropdown mi_enlace">
                      <div className="m_negro dropbtn">🠻 Menú</div>
                      <Drop />
                    </div>
                  :
                    <Drop />
                :
                  <div className="mi_enlace" onClick={() => {
                    setModalLogin({
                      ver: true,
                      tipo: 'Ingresar',
                    })
                  }}>Ingresar</div>
              }
            </div>
            <div className="redes quitar_redes">
              {
                window._my_redes.map((item, i) => 
                  <a href={item.link} target="_new" className="de_flex_redes grises" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <Element name="inicio"></Element>
      <ModalLogin modalLogin={modalLogin} setModalLogin={setModalLogin}/>
    </div>
  );
}

export default Inicio;
