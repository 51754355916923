import { useEffect, useState/*, useCallback*/ } from 'react';
import Axios from 'axios';
import Form from 'react-bootstrap/Form';
import "bootstrap/dist/css/bootstrap.min.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import splash from './splash'
import alerta from './alerta'
import limpiarVariables from './limpiar'
import Cuenta from './../Cuenta'

function ModalLogin(params) {
  const [usuario, set_usuario] = useState("");
  const [contrasena, set_contrasena] = useState("");
  //const [modal, setModal] = useState(false);

  const [pass, set_pass] = useState("");
  const [passw, set_passw] = useState("");
  
  const [index, setIndex] = useState(0)

  useEffect(() => {
    splash()
    /*
    if(localStorage._ROL){
      window.location.href = window.rutas+"inicio";
    }
    */
  }, []);

  const limpiar = () => {
    set_usuario("")
    set_contrasena("")

    set_pass("")
    set_passw("")
  }

  return (
    <div>
      <Modal
        show={params.modalLogin.ver}
        onHide={() => {
          params.setModalLogin({
            ver: false,
            tipo: '',
          })
          limpiar()
        }}
        size={params.modalLogin.tipo==='Registro' ? "lg" : null}
        centered
        style={{zIndex: index>0 ? 3 : null}}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h15>{params.modalLogin.tipo}</h15>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            params.modalLogin.tipo==='Ingresar' ?
              <Form>
                <Form.Group>
                  <Form.Label>*Usuario:</Form.Label>
                  <Form.Control type="text" placeholder="Correo electrónico." value={usuario} onChange={(event) => {set_usuario(event.target.value)}} required/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>*Contraseña:</Form.Label>
                  <Form.Control type="password" value={contrasena} onChange={(event) => {set_contrasena(event.target.value)}} required/>
                </Form.Group>
                <div className="espacio_input"/>
                <div className="flex_recuperar">
                  <div className="recuperar" onClick={() => {
                    params.setModalLogin({
                      ver: true,
                      tipo: 'Registro',
                    })
                  }}>Registrarme</div>
                  <div className="separador">|</div>
                  <div className="recuperar" onClick={() => {
                    params.setModalLogin({
                      ver: true,
                      tipo: 'Recuperar contraseña',
                    })
                  }}>Recuperar contraseña</div>
                </div>
                <div id="cl"></div>
                <div className="fin_botones">
                  <Button type="submit" variant="success" onClick={(event) => {
                    event.preventDefault()
                    if(usuario===''){
                      alerta('error','Ingresa tu usuario');
                    }else if(contrasena===''){
                      alerta('error','Ingresa tu contraseña');
                    }else{
                      window.H5_loading.show();
                      var pasar = new URLSearchParams();
                      pasar.append('usuario', limpiarVariables(usuario));
                      pasar.append('contrasena', limpiarVariables(contrasena));
                      pasar.append('_CLUSTER', 'clusterminerodehidalgo');
                      Axios.post(`${window.ws}wsLogin.php`, pasar)
                        .then(response => {
                          if(response.data.elError===1){
                            localStorage.setItem('usuario', usuario)
                            localStorage.setItem('_ROL', response.data.elRol)
                            localStorage.setItem('_Nombre', response.data.elNombre)
                            localStorage.setItem('_Activa', response.data.elActiva)

                            window.location.href = window.rutas+"cuenta";
                            //alerta('success','si');
                          }else{
                            alerta('error',''+response.data.mensaje);
                          }
                        })
                        .catch(error => {
                          console.log(error)
                        })
                        .then(function() {
                          window.H5_loading.hide();
                        })
                    }
                  }}>
                    Enviar
                  </Button>
                </div>
              </Form>
            : params.modalLogin.tipo==='Registro' ?
              <Cuenta cual="login" modalLogin={params.modalLogin} setModalLogin={params.setModalLogin} setIndex={setIndex}/>
            : params.modalLogin.tipo==='Recuperar contraseña' ?
              <Form>
                <Form.Group>
                  <Form.Label>*Correo:</Form.Label>
                  <Form.Control type="text" placeholder="Correo electrónico" value={usuario} onChange={(event) => {set_usuario(event.target.value)}} required/>
                  <div className="login_chica">El correo electrónico debe ser el que registraste en el Cluster Minero de Hidalgo.</div>
                </Form.Group>
                <div className="espacio_input"/>
                <div className="campos">
                  Campos obligatorios (*)
                </div>
                <div id="cl"></div>
                <div className="fin_botones">
                  <Button type="submit" variant="success" onClick={(event) => {
                    event.preventDefault()
                    if(!usuario){
                      alerta('error','Un campo requerido esta vacío');
                    }else{
                      window.H5_loading.show();
                      var pasar = new URLSearchParams();
                      //pasar.append('que', 'recuperar');
                      //pasar.append('correo', limpiar(correo));
                      pasar.append('usuario', limpiarVariables(usuario));
                      pasar.append('_CLUSTER', 'clusterminerodehidalgo');
                      Axios.post(`${window.ws}wsRecuperar.php`, pasar)
                        .then(response => {
                          if(response.data.elError===1){
                            alerta('success',''+response.data.mensaje);
                            //limpiar()
                            //limpiarDos()
                            params.setModalLogin({
                              ver: false,
                              tipo: '',
                            })
                          }else{
                            alerta('error',''+response.data.mensaje);
                          }
                        })
                        .catch(error => {
                          console.log(error)
                        })
                        .then(function() {
                          window.H5_loading.hide();
                        })
                    }
                  }}>
                    Enviar
                  </Button>
                </div>
              </Form>
            : params.modalLogin.tipo ?
              <Form>
                <Form.Group>
                  <Form.Label>*Nueva contraseña:</Form.Label>
                  <Form.Control type="password" placeholder="Contraseña nueva" value={pass} onChange={(event) => {set_pass(event.target.value)}} required/>
                  <div className="espacio_input"/>
                  <Form.Control type="password" placeholder="Confirmar contraseña nueva" value={passw} onChange={(event) => {set_passw(event.target.value)}} required/>
                </Form.Group>
                <div className="espacio_input"/>
                <div className="campos">
                  Campos obligatorios (*)
                </div>
                <div id="cl"></div>
                <div className="fin_botones">
                  <Button type="submit" variant="success" onClick={(event) => {
                    event.preventDefault()
                    const url = window.location.href.split('?enlace=')
                    if(!pass || !passw || !url[1]){
                      alerta('error','Un campo requerido esta vacío');
                    }else{
                      if(pass===passw){
                        window.H5_loading.show();
                        var pasar = new URLSearchParams();
                        pasar.append('que', 'recuperar');
                        pasar.append('pass', pass);
                        pasar.append('passw', passw);
                        pasar.append('enlace', url[1]);
                        pasar.append('_CLUSTER', 'clusterminerodehidalgo');
                        Axios.post(`${window.ws}wsRecuperar.php`, pasar)
                          .then(response => {
                            if(response.data.elError===1){
                              alerta('success',''+response.data.mensaje);
                              set_pass("")
                              set_passw("")
                              set_usuario(url[1].slice(10))
                              params.setModalLogin({
                                ver: true,
                                tipo: 'Ingresar',
                              })
                            }else{
                              alerta('error',''+response.data.mensaje);
                            }
                          })
                          .catch(error => {
                            console.log(error)
                          })
                          .then(function() {
                            window.H5_loading.hide();
                          })
                      }else{
                        alerta('error','Las contraseñas no coinciden');
                      }
                    }
                  }}>
                    Enviar
                  </Button>
                </div>
              </Form>
            :
              null
          }
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalLogin;
