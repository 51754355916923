import { useEffect, useState/*, useCallback*/ } from 'react';
import Axios from 'axios';
import CountUp from 'react-countup';
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import splash from './ultilidades/splash'
import ModalLogin from './ultilidades/ModalLogin'
//import MisEventos from './MisEventos'
import { Link/*, useHistory*/ } from 'react-router-dom'
//import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { miMenu, } from './../redux/menuRedux'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const alerta = (tipo,titulo) =>{
  Swal.fire({
    type: tipo,
    title: titulo
  });
}

function Inicio() {
  const dispatch = useDispatch()
  const [modalLogin, setModalLogin] = useState({
    ver: false,
    tipo: '',
  });
  const [modal, setModal] = useState(false);
  const [index, setIndex] = useState(0);
  //const [enlace, set_enlace] = useState("");

  const [lista_blog, set_lista_blog] = useState([])
  const listaTodas = () => {
    window.H5_loading.show();
    var pasar = new URLSearchParams();
    pasar.append('_CLUSTER', 'clusterminerodehidalgo');
    pasar.append('que', 'blog');
    pasar.append('seccion', 'noticias');

    Axios.post(`${window.ws}wsSelect.php`, pasar)
      .then(response => {
        set_lista_blog(response.data.lista_array)
      })
      .catch(error => {
        console.log(error)
        //alert(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  const [lista_articulos, set_lista_articulos] = useState([])
  const listaArticulos = () => {
    window.H5_loading.show();
    var pasar = new URLSearchParams();
    pasar.append('_CLUSTER', 'clusterminerodehidalgo');
    pasar.append('que', 'blog');
    pasar.append('seccion', 'articulos');

    Axios.post(`${window.ws}wsSelect.php`, pasar)
      .then(response => {
        set_lista_articulos(response.data.lista_array)
      })
      .catch(error => {
        console.log(error)
        //alert(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  const [numero_academia, set_numero_academia] = useState(0)//eslint-disable-line
  const [numero_gobierno, set_numero_gobierno] = useState(0)//eslint-disable-line
  const [numero_proveedora, set_numero_proveedora] = useState(0)//eslint-disable-line
  const [numero_minera, set_numero_minera] = useState(0)//eslint-disable-line
  const [numero_camara, set_numero_camara] = useState(0)//eslint-disable-line
  const contador = () => {
    window.H5_loading.show();
    var pasar = new URLSearchParams();
    pasar.append('_CLUSTER', 'clusterminerodehidalgo');

    Axios.post(`${window.ws}wsSelect.php`, pasar)
      .then(response => {
        set_numero_academia(response.data.numero_academia)
        set_numero_gobierno(response.data.numero_gobierno)
        set_numero_proveedora(response.data.numero_proveedora)
        set_numero_minera(response.data.numero_minera)
        set_numero_camara(response.data.numero_camara)

        //alert(response.data.numero_proveedora)
      })
      .catch(error => {
        console.log(error)
        //alert(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  let initialized = false
  useEffect(() => {
    listaTodas()
    listaArticulos()
    contador()
    if(!initialized){
      initialized = true //eslint-disable-line
      splash()
      const error = window.location.href.split('?')
      if(error[1]==='mensaje=error'){

      const url = window.location.href.split('?enlace=')
      if(url[1]){
        existe()
      }

        alerta('error', 'No tienes permisos para entrar a esa sección, ingresar nuevamente')
      }else if(error[1]==='activar=no'){
        alerta('error', 'Tu cuenta ya ha sido activada, para ingresar tienes que llenar los campos de usuario y contraseña')
        ingresar()
      }else if(error[1]==='activar=si'){
        alerta('success', 'Tu cuenta ha sido activada, para ingresar tienes que llenar los campos de usuario y contraseña')
        ingresar()
      }
    }
  }, []);

  const ingresar = () => {
    setModalLogin({
      ver: true,
      tipo: 'Ingresar',
    })
  }

  const existe = () => {
    window.H5_loading.show();
    var params = new URLSearchParams();
    const url = window.location.href.split('?enlace=')
    params.append('enlace', url[1]);
    params.append('que', 'verificar');
    params.append('_CLUSTER', 'clusterminerodehidalgo');
    Axios.post(`${window.ws}wsRecuperar.php`, params)
      .then(response => {
        if(response.data.elError===1){
          //alerta('success', response.data.mensaje)
          //set_enlace(url[1])
          //setModal(true)
          setModalLogin({
            ver: true,
            tipo: 'Restablecer contraseña',
          })
        }else{
          alerta('error', response.data.mensaje)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  //let suma = 0
  return (
    <div>
      <div className="bienvenida">
        <div className="max_width">
          <div className="flex_bienvenida">
            <div></div>
            <div className="de_flex_bienvenida">
              <div className="bienvenida_titulo">MENSAJE DE BIENVENIDA</div>
              Le damos la más cordial bienvenida al Clúster Minero de Hidalgo, donde esperamos trabajar de manera coordinada con los diferentes entes que lo conforman, con el principal objetivo de incrementar la competitividad del sector, en entorno positivo y enfoque sostenible.
              <br/><br/>
              Estamos emocionados de ver lo que podemos lograr, por ello te invitamos a formar parte de esta agrupación.
            </div>
          </div>
        </div>
      </div>
      {/*<img src="img/bot_1.png" alt="Clúster Minero" className="bot_1"/>*/}
      <Carousel fade controls={false} interval={3000} pause={false} indicators={false}>
        {
          window._my_carousel.map((item, i) =>
            <Carousel.Item key={i} className="car_img" style={{backgroundImage: `url(${item.foto})`}}>
              {/*
              <div className="car_fondo">
                <div>
                  {item.texto ? <h8>{item.texto}</h8> : null}
                  {item.titulo ? <h9>{item.titulo}</h9> : null}
                  {item.www ? <a href={item.www} className="car_url" target="new">{item.www_decir}</a> : null}
                  {item.ir ? <Ir to={item.ir} className="car_url">{item.ir_decir}</Ir> : null}
                </div>
              </div>
              */}
            </Carousel.Item>
          )
        }
      </Carousel>
      <div id="redes">
        <div className="redes">
          {
            window._my_redes.map((item, i) => 
              <a href={item.link} target="_new" className="de_flex_redes grises" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
            )
          }
        </div>
      </div>
      {
        window._my_cifras.length>0 ?
          <div className="fondo_nosotros">
            <div className="max_width padding_width">
              <center>
                <h11>Los que forman parte de este clúster minero</h11>
              </center>
              <div className="flex_cifras">
                {/*
                  window._my_cifras.map((item, i) =>
                    <div className="de_flex_cifras" key={i}>
                      <h17>
                        <CountUp
                          start={0}
                          end={item.cantidad}
                          delay={2}
                          duration={10}
                          onEnd={({ countUp, pauseResume, reset, start, update })  => {
                            pauseResume()
                            reset()
                            start()
                          }}
                        >
                          {({ countUpRef, pauseResume, reset, start, update }) => (
                            <div>
                              <span ref={countUpRef} />
                            </div>
                          )}
                        </CountUp>
                      </h17>
                      {item.titulo}
                    </div>
                  )
                */}
                {
                  window._my_cifras.map((item, i) => {
                    const numero = eval(item.variable) //eslint-disable-line
                    return(
                      <div className="de_flex_cifras" key={i}>
                        <h17>
                          <CountUp delay={2} end={numero} />
                        </h17>
                        {item.titulo}
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        :
          null
      }
      <div className="fondo_gris">
        <div className="max_width padding_width">
          <center>
            <h14>Intégrate a nuestro clúster minero</h14>
          </center>
          {
            window._my_empresas.length>0 ?
              <div className="flex_logos">
                {
                  window._my_empresas.map((item, i) =>
                    <img src={item.img} title={item.titulo} alt={item.titulo} key={i}/>
                  )
                }
              </div>
            :
              null
          }
          <center>
            <button className="boton" onClick={() => {
              setModalLogin({
                ver: true,
                tipo: 'Registro',
              })
            }}>Regístrate</button>
          </center>
        </div>
      </div>
      <div className="galeria" >
        {
          window._my_galeria.map((item, i) =>
            <div key={i} className="my_galeria" onClick={() => {
              setModal(true)
              setIndex(item.id)
            }}>
              <img src={item.foto} title={item.titulo} alt={item.titulo}/>
              <div className="hover_galeria">
                <div className="cajas_galeria">
                  {item.titulo}
                  <div className="linea_galeria"/>
                  <h13>{item.fecha}</h13>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div id="cl"></div>
      {/*
      <div className="fondo_servicios">
        <div className="max_width padding_width">
          <center>
            <h11>Eventos recientes</h11>
            <h14>Eventos realizados por el Clúster Minero de Hidalgo</h14>
          </center>
          <MisEventos inicial={0} mostrar={3} arreglo={window._my_servicios}/>
          <center><Link to="eventos" className="boton">Ver más eventos</Link></center>
        </div>
      </div>
      */}
      <div className="fondo_gris">
        <div className="max_width padding_width">
          <center>
            <h11>Eventos y Noticias</h11>
            <h14>ENTÉRATE DE LO MÁS RELEVANTE EN EL SECTOR MINERO</h14>
          </center>
          {
            lista_blog.length>0 ?
              <div className="noticias_inicio">
                {
                  lista_blog.map((item, i) => {
                    let foto = ""
                    if(item.bl_foto){
                      foto = `${window.ws}documentos/blog/${item.bl_id}.${item.bl_foto}?v=${item.bl_v}`
                    }else{
                      foto = "img/fondo.svg"
                    }

                    let texto = item.bl_texto
                    if(item.bl_texto.length>150){
                      texto = item.bl_texto.substring(0,150)+"..."
                    }

                    let miFecha = new Date(`${item.bl_fecha.split(" ")[0]}T00:00`)
                    return(
                      <div className="my_servicio" data-aos="fade-down" key={i}>
                        <div className="box_servicio">
                          <div className="caja_servicio">
                            <div className="servicio_relative">
                              <img src={foto} title={item.bl_titulo} alt={item.bl_titulo}/>  
                              <div className="servicio_contrato">
                                <Link to={`./nota/${item.bl_id}`}>
                                  Ver más
                                </Link>
                              </div>
                            </div>
                            <div className="servicio_info">
                              <center>
                                <h15>{item.bl_titulo}</h15>
                                <br/>
                                {`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}
                              </center>
                              <div dangerouslySetInnerHTML={{ __html: texto }} className="texto_servicios"/>
                              <Link to={`./nota/${item.bl_id}`} className="decoration">
                                <div className="servicio_mas">
                                  Ver más detalles
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            :
              null
          }
          <center><Link to="noticias" className="boton" onClick={() => dispatch(miMenu('noticias'))}>Ver más noticias</Link></center>
        </div>
      </div>
      <div className="fondo_servicios">
        <div className="max_width padding_width">
          <center>
            <h11>Información Minera</h11>
            <h14>INFORMACIÓN IMPORTANTE DEL SECTOR MINERO</h14>
          </center>
          {
            lista_articulos.length>0 ?
              <div className="noticias_inicio">
                {
                  lista_articulos.map((item, i) => {
                    let foto = ""
                    if(item.bl_foto){
                      foto = `${window.ws}documentos/blog/${item.bl_id}.${item.bl_foto}?v=${item.bl_v}`
                    }else{
                      foto = "img/fondo.svg"
                    }

                    let texto = item.bl_texto
                    if(item.bl_texto.length>150){
                      texto = item.bl_texto.substring(0,150)+"..."
                    }

                    let miFecha = new Date(`${item.bl_fecha.split(" ")[0]}T00:00`)
                    return(
                      <div className="my_servicio" data-aos="fade-down" key={i}>
                        <div className="box_servicio">
                          <div className="caja_servicio">
                            <div className="servicio_relative">
                              <img src={foto} title={item.bl_titulo} alt={item.bl_titulo}/>  
                              <div className="servicio_contrato">
                                <Link to={`./nota/${item.bl_id}`}>
                                  Ver más
                                </Link>
                              </div>
                            </div>
                            <div className="servicio_info">
                              <center>
                                <h15>{item.bl_titulo}</h15>
                                <br/>
                                <div className="fecha_not_sec"><b>{window._my_blog_categorias.filter(p => p.id===item.bl_tipo)[0].nombre}</b> | {`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</div>
                              </center>
                              <div dangerouslySetInnerHTML={{ __html: texto }} className="texto_servicios"/>
                              <Link to={`./nota/${item.bl_id}`} className="decoration">
                                <div className="servicio_mas">
                                  Ver más detalles
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            :
              null
          }
          <center><Link to="articulos" className="boton" onClick={() => dispatch(miMenu('articulos'))}>Ver más artículos</Link></center>
        </div>
      </div>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false)
        }}
        size="lg"
        className="my-modal"
        centered
      >
        <Modal.Body >
          <Carousel className="slider_modal" defaultActiveIndex={index} interval={1500}>
            {
              window._my_galeria.map((item, i) =>
                <Carousel.Item key={i}>
                  <img src={item.foto} alt="Slider"/>
                </Carousel.Item>
              )
            }
          </Carousel>
        </Modal.Body>
      </Modal>
      <ModalLogin modalLogin={modalLogin} setModalLogin={setModalLogin}/>
    </div>
  );
}

export default Inicio;
