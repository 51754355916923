import { useEffect, useState/*, useCallback*/ } from 'react';
import splash from './ultilidades/splash'
import Cuenta from './Cuenta'
import { Element, Link as Ir } from 'react-scroll';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Button from 'react-bootstrap/Button';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import Axios from 'axios';
import alerta from './ultilidades/alerta'
import cerrarSesion from './ultilidades/cerrar'

function Usuarios() {
  const [modalLogin, setModalLogin] = useState({
    ver: false,
    tipo: '',
  });
  const [tipoConsulta, setTipoConsulta] = useState(0)
  const [inicial, setInicial] = useState(0)
  const [mostrar, setMostrar] = useState(25)
  const [cantidad, setCantidad] = useState(25)

  const [id, set_id] = useState(false);

  const [lista_usuarios, set_lista_usuarios] = useState([])
  const [datos, setDatos] = useState([])
  const miDatos = (editar) => {
    window.H5_loading.show();
    var pasar = new URLSearchParams();
    pasar.append('_USUARIO', localStorage.usuario);
    pasar.append('_ROL', localStorage._ROL);
    pasar.append('_Activa', localStorage._Activa);

    Axios.post(`${window.ws}wsUsuarios.php`, pasar)
      .then(response => { 
        if(response.data.elError===3){
          cerrarSesion(1)
        }else{
          set_lista_usuarios(response.data.lista_array)
          if(response.data.elError===1){
            if(editar==='editar'){
              let lista_filtrada = response.data.lista_array.filter(p => eval(condicion_consulta)) //eslint-disable-line
              ordenar(sort, lista_filtrada)
            }
          }else{
            alerta('error', ''+response.data.mensaje)
            console.log(response.data.mensaje)
          }
        }
      })
      .catch(error => {
        //alerta('error', ''+error)
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  let initialized = false
  useEffect(() => {
    
    if(!initialized){
      initialized = true //eslint-disable-line
      splash()
      miDatos()
    }
  }, []);

  const [c_nombre, set_c_nombre] = useState([]);
  const [c_sector, set_c_sector] = useState([]);
  const [c_status, set_c_status] = useState("");

  let condicion_consulta = `p.us_id`
  if(c_sector.length>0){
    condicion_consulta += `&& (`
    c_sector.map((item, i) => { //eslint-disable-line
      let or = " || "
      if(i===0){
        or = ""
      }
      condicion_consulta += `${or}p.us_sector==='${item.sector}'`
    })
    condicion_consulta += `)`
  }
  if(c_nombre.length>0){
    condicion_consulta += `&& (`
    c_nombre.map((item, i) => { //eslint-disable-line
      let or = " || "
      if(i===0){
        or = ""
      }
      condicion_consulta += `${or}p.us_nombre==='${item.us_nombre}'`
    })
    condicion_consulta += `)`
  }
  if(c_status){
    condicion_consulta += `&& p.us_status===${c_status}`
  }
  let lista_filtrada = lista_usuarios.filter(p => eval(condicion_consulta)) //eslint-disable-line

  const [sort, set_sort] = useState('nombre')
  const [sort_orden, set_sort_orden] = useState(0)
  
  const limpiar = () => {
    set_c_nombre([])
    set_c_sector([])
    set_c_status("")

    setInicial(0)
    setMostrar(25)
    setCantidad(25)

    setDatos([])
    set_sort('nombre')
    set_sort_orden(0)
  }
  
  const ordenar = (orden, array) => {
    let mi_orden = 0
    if(array){
      mi_orden = sort_orden
    }else if(orden===sort && sort_orden===0){
      set_sort_orden(1)
      mi_orden = 1
    }else{
      set_sort_orden(0)
    }

    let elige = []
    if(array){
      elige = array
    }else{
      elige = datos
    }
    //Por orden alfabético según nombre
    const arreglo = window.newObject(elige).sort((a, b) => {
      //Se usa localeCompare para que funcione correctamente con acentos
      if(orden==='usuario'){
        if(mi_orden>0){
          return b.us_usuario.localeCompare(a.us_usuario);
        }else{
          return a.us_usuario.localeCompare(b.us_usuario);
        }
      }else /*if(orden==='edad'){
        if(mi_orden>0){
          return window._my_edad(a.per_rfc) - window._my_edad(b.per_rfc);
        }else{
          return window._my_edad(b.per_rfc) - window._my_edad(a.per_rfc);
        }
      }else */if(orden==='sector'){
        if(mi_orden>0){
          return b.us_sector.localeCompare(a.us_sector);
        }else{
          return a.us_sector.localeCompare(b.us_sector);
        }
      }else if(orden==='status'){
        let a_sor = 'Nuevo'
        if(a.us_status===3){
          a_sor = 'Activo'
        }else if(a.us_status===2){
          a_sor = 'Inactivo'
        }else if(a.us_status===1){
          a_sor = 'Baja'
        }
        let b_sor = 'Nuevo'
        if(b.us_status===3){
          b_sor = 'Activo'
        }else if(b.us_status===2){
          b_sor = 'Inactivo'
        }else if(b.us_status===1){
          b_sor = 'Baja'
        }
        if(mi_orden>0){
          return b_sor.localeCompare(a_sor);
        }else{
          return a_sor.localeCompare(b_sor);
        }
      }else{
        if(mi_orden>0){
          return b.us_nombre.localeCompare(a.us_nombre);
        }else{
          return a.us_nombre.localeCompare(b.us_nombre);
        }
      }
    });
    //Cada objeto es diferente
    setDatos(arreglo)
    setInicial(0)
    set_sort(orden)
  }

  return (
    <div>
      <div className="fondo_titulos">
        <div className="max_width texto_titulo">
          <br/>Usuarios
        </div>
      </div>
      <div className="fondo_gris">
        <div className="max_width padding_width">
          <center>
            <h11>Registro y consulta de usuarios</h11>
          </center>
          <Form>
            <div className="flex_act_nueva">
              <div className="flex_nueva" onClick={() => {
                setModalLogin({
                  ver: true,
                  tipo: '',
                })
              }}>
                <div className="nueva">+</div>
                <div className="l_nueva">Registrar usuario</div>
              </div>
            </div>
            <h13>Realiza una consulta:</h13>
            <Form.Group>
              <div className="flex_botones">
                <div id={tipoConsulta===0 ? 'boton_marca' : null} onClick={() => setTipoConsulta(0)}>Consulta rápida</div>
                <div id={tipoConsulta===1 ? 'boton_marca' : null} onClick={() => setTipoConsulta(1)}>Consulta avanzada</div>
              </div>
            </Form.Group>
            <div className="espacio_input"/>
            <div className="formulario_dos sin_tanto_espacio">
              <div className="de_formulario_dos">
                <Form.Group>
                  <Form.Label>Nombre:</Form.Label>
                  <Typeahead
                    id="basic-typeahead-multiple"
                    multiple
                    labelKey="us_nombre"
                    onChange={set_c_nombre}
                    options={lista_usuarios}
                    selected={c_nombre}
                    emptyLabel="No hay resultados."
                    placeholder="Selecciona uno o multiples."
                    renderMenuItemChildren={(item) => (
                      <div className={item.us_status===1 ? 'color_rojo' : item.us_status===2 ? 'color_naranja' : null}>
                        {item.us_nombre}
                      </div>
                    )}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Sector:</Form.Label>
                  <Typeahead
                    id="basic-typeahead-multiple"
                    multiple
                    labelKey="nombre"
                    onChange={set_c_sector}
                    options={window._my_sector}
                    selected={c_sector}
                    emptyLabel="No hay resultados."
                    placeholder="Selecciona uno o multiples."
                  />
                </Form.Group>
              </div>
              {
                tipoConsulta>0 ?
                  <div className="de_formulario_dos">
                    <Form.Group>
                      <Form.Label>Estatus:</Form.Label>
                      <Form.Select onChange={(event) => set_c_status(event.target.value)}>
                        <option selected={c_status==="" ? true : false}></option>
                        <option selected={c_status===2 ? true : false} value={2}>Inactivo</option>
                        <option selected={c_status===3 ? true : false} value={3}>Activo</option>
                        <option selected={c_status===1 ? true : false} value={1}>Baja</option>
                        <option selected={c_status===0 ? true : false} value={0}>Nuevo</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                :
                  null
              }
            </div>
            <div className="espacio_input"/>
            <Button variant="secondary" className="espacio_boton" onClick={limpiar}>
              Limpiar
            </Button>
            {
              lista_filtrada.length>0 ?
                <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={100}>
                  <Button variant="success" onClick={() => {
                    setDatos(lista_filtrada)
                    setInicial(0)
                    setMostrar(25)
                    setCantidad(25)
                    set_sort('nombre')
                    set_sort_orden(0)
                  }}>
                    Consultar ({lista_filtrada.length})
                  </Button>
                </Ir>
              :
                <Button variant="warning" onClick={() => setDatos([])}>
                  No hay información con esos criterios
                </Button>
            }
          </Form>
          <Element name="recorrer"></Element>
          {
            datos.length>0 ? 
              <div>
                <div className="linea_consulta"/>
                <div className="excel">
                  <ReactHTMLTableToExcel
                    className="boton_excel"
                    table="table-to-xls"
                    filename="Reporte_usuarios"
                    buttonText={<img src="img/ico_excel.svg" alt="Excel" className="hvr-push" title="Descargar Excel"/>}
                  />
                </div>
                <div className="flex_resultados">
                  Resultados encontrados: {datos.length}
                  {
                    datos.length>25 ? 
                      <select name="mostrar" onChange={(event) => {
                        setInicial(0)
                        setMostrar(parseInt(event.target.value))
                        setCantidad(parseInt(event.target.value))
                      }}>
                        <option value={25} selected={cantidad===25 ? true : false}>25</option>
                        {datos.length>=50 ? <option value={50} selected={cantidad===50 ? true : false}>50</option> : null}
                        {datos.length>=100 ? <option value={100} selected={cantidad===100 ? true : false}>100</option> : null}
                        {datos.length>=500 ? <option value={500} selected={cantidad===500 ? true : false}>500</option> : null}
                        {datos.length>=1000 ? <option value={1000} selected={cantidad===1000 ? true : false}>1000</option> : null}
                        <option value={99999999999999999999} selected={cantidad===99999999999999999999 ? true : false}>Todos</option>
                      </select>
                    :
                      null
                  }
                </div>
                <div className="tabla_responsiva">
                  <table className="carrillo" cellspacing="3">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th onClick={() => ordenar('nombre')} className={sort==='nombre' ? 'th_orden' : 'tabla_orden'}>Nombre<div>⮁</div></th>
                        <th onClick={() => ordenar('usuario')} className={sort==='rfc' ? 'th_orden' : 'tabla_orden'}>Usuario<div>⮁</div></th>
                        <th onClick={() => ordenar('sector')} className={sort==='edad' ? 'th_orden' : 'tabla_orden'}>Sector<div>⮁</div></th>
                        <th>Teléfono</th>
                        <th onClick={() => ordenar('status')} className={sort==='status' ? 'th_orden' : 'tabla_orden'}>Estatus<div>⮁</div></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        datos.slice(inicial, mostrar).map((item, i) =>
                          <tr key={i}>
                            <td>{inicial+i+1}</td>
                            <td>{item.us_nombre}</td>
                            <td>{item.us_usuario}</td>
                            <td>{item.us_sector}</td>
                            <td>{item.us_tel}{item.us_tel ? <br/> : null}{item.us_cel}</td>
                            <td>
                              {
                                item.us_status===1 ?
                                  <div className="color_rojo">Baja</div>
                                : item.us_status===2 ?
                                  <div className="color_naranja">Inactivo</div>
                                : item.us_status===3 ?
                                  <div className="color_verde">Activo</div>
                                :
                                  "Nuevo"
                              }
                            </td>
                            <td>
                              <img src="img/editar.png" alt="Editar" title="Editar" className="hvr-push icon_form" onClick={() => {
                                set_id(item.us_id)
                                setModalLogin({
                                  ver: true,
                                  tipo: '',
                                })
                              }}/>
                              {
                                item.us_status>1 ? 
                                  <img src="img/eliminar.png" alt="Baja" title="Baja" className="hvr-push icon_form" onClick={() => {
                                    Swal.fire({
                                      title: '¿Deseas dar de baja al usuario?',
                                      //text: "You won't be able to revert this!",
                                      type: 'warning',
                                      showCancelButton: true,
                                      confirmButtonColor: '#3085d6',
                                      cancelButtonColor: '#d33',
                                      confirmButtonText: 'Sí, de acuerdo!'
                                    }).then((result) => {
                                      if(result.value){
                                        window.H5_loading.show();
                                        var pasar = new URLSearchParams();
                                        pasar.append('_USUARIO', localStorage.usuario);
                                        pasar.append('_ROL', localStorage._ROL);
                                        pasar.append('_Activa', localStorage._Activa);
                                        
                                        pasar.append('que', 'borrar');
                                        pasar.append('us_id', item.us_id);
                                        Axios.post(`${window.ws}wsUsuarios.php`, pasar)
                                          .then(response => {
                                            if(response.data.elError===3){
                                              cerrarSesion(1)
                                            }else if(response.data.elError===1){
                                              alerta('success',''+response.data.mensaje);
                                              miDatos('editar')
                                            }else{
                                              alerta('error',''+response.data.mensaje);
                                            }
                                          })
                                          .catch(error => {
                                            console.log(error)
                                          })
                                          .then(function() {
                                            window.H5_loading.hide();
                                          })
                                      }
                                    })
                                  }}/>
                                :
                                  null
                              }
                            </td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
                <div className="flex_siguiente">
                  <div>{inicial===0 ? null : <Button variant="dark" onClick={() => {
                    setMostrar(mostrar-cantidad)
                    setInicial(inicial-cantidad)
                  }}>🡨</Button>}</div>
                  <div>{mostrar>=datos.length ? null : <Button variant="dark" onClick={() => {
                    setMostrar(mostrar+cantidad)
                    setInicial(inicial+cantidad)
                  }}>🡪</Button>}</div>
                </div>
                <div className="tabla_responsiva">
                <table id="table-to-xls" style={{display:"none"}}>
                  <tr>
                    <th>#</th>
                    <th>Nombre</th>
                    <th>Usuario</th>
                    <th>Sector</th>
                    <th>Otro sector</th>
                    <th>Nombre del contacto</th>
                    <th>Cargo del contacto</th>
                    <th>Reactor o Director</th>
                    <th>CURP</th>
                    <th>RFC</th>
                    <th>Razón Social</th>
                    <th>RFC Razón Social</th>
                    <th>Tipo</th>
                    <th>Giro</th>
                    <th>Teléfono</th>
                    <th>Celular</th>
                    <th>Correo</th>
                    <th>Sitio web</th>
                    <th>Servicios</th>
                    <th>Estado</th>
                    <th>Municipio</th>
                    <th>Domicilio</th>
                    <th>Código postal</th>
                    <th>Estatus</th>
                  </tr>
                  {
                    datos.map((item, i) => {
                      return(
                        <tr key={i}>
                          <td>{i+1}</td>
                          <td>{item.us_nombre}</td>
                          <td>{item.us_usuario}</td>
                          <td>{item.us_sector}</td>
                          <td>{item.us_especifique}</td>
                          <td>{item.us_nombre_dos}</td>
                          <td>{item.us_cargo}</td>
                          <td>{item.us_nombre_tres}</td>
                          <td>{item.us_curp}</td>
                          <td>{item.us_rfc}</td>
                          <td>{item.us_razon}</td>
                          <td>{item.us_rfc_dos}</td>
                          <td>{item.us_tipo}</td>
                          <td>{item.us_giro}</td>
                          <td>{item.us_tel}</td>
                          <td>{item.us_cel}</td>
                          <td>{item.us_correo}</td>
                          <td>{item.us_web}</td>
                          <td>{item.us_servicios}</td>
                          <td>{item.us_estado}</td>
                          <td>{item.us_municipio}</td>
                          <td>{item.us_domicilio}</td>
                          <td>{item.us_cp}</td>
                          <td>
                            {
                              item.us_status===1 ?
                                "Baja"
                              : item.us_status===2 ?
                                "Inactivo"
                              : item.us_status===3 ?
                                "Activo"
                              :
                                "Nuevo"
                            }
                          </td>
                        </tr>
                      )
                    })
                  }
                </table>
                </div>
              </div>
            :
              null
          }
        </div>
      </div>
      <Modal
        show={modalLogin.ver}
        onHide={() => {
          setModalLogin({
            ver: false,
            tipo: '',
          })
        }}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h15>{id>0 ? 'Editar' : 'Registrar'} usuario</h15>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Cuenta cual={id>0 ? 'editar' : 'nuevo'} susDatos={miDatos} id={id} set_id={set_id} modalLogin={modalLogin} setModalLogin={setModalLogin}/>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Usuarios;
