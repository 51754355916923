//import { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Menu from './components/Menu'
//import Header from './components/Header'
import Pie from './components/Pie'

import Inicio from './components/Inicio'
import Nosotros from './components/Nosotros'
import Eventos from './components/Eventos'
import Noticias from './components/Noticias'
import Nota from './components/Nota'
import Servicios from './components/Servicios'
import Contacto from './components/Contacto'
import Micuenta from './components/Micuenta'
import Usuarios from './components/Usuarios'
import Publicar from './components/Publicar'

/*
import ReactGA from 'react-ga';
const TRACKING_ID = "G-EQG4G573S6"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
*/
/*
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-EQG4G573S6"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-EQG4G573S6');
</script>
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-231287964-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
*/

function App() {
  /*
  let initialized = false
  useEffect(() => {
    if(!initialized){
      //ReactGA.pageview(window.location.pathname + window.location.search);
      initialized = true //eslint-disable-line
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  */

  return (
    <BrowserRouter>
      <Menu />
      <Switch>
        <Route exact path="/" component={Inicio} />
        <Route path={window.path+"/inicio"} component={Inicio} />
        <Route path={window.path+"/nosotros"} component={Nosotros} />
        <Route path={window.path+"/eventos"} component={Eventos} />
        <Route path={window.path+"/noticias"} component={Noticias} />
        <Route path={window.path+"/articulos"} component={Noticias} />
        <Route path={window.path+"/nota/:id"} component={Nota} />
        <Route path={window.path+"/servicios"} component={Servicios} />
        <Route path={window.path+"/contacto"} component={Contacto} />
        {localStorage._ROL ? <Route path={window.path+"/cuenta"} component={Micuenta} /> : null}
        {localStorage._ROL==='ADMIN_CLUSTER' ? <Route path={window.path+"/usuarios"} component={Usuarios} /> : null}
        {localStorage._ROL==='ADMIN_CLUSTER' ? <Route path={window.path+"/publicar"} component={Publicar} /> : null}
        <Route component={Inicio} />
      </Switch>
      <Pie />
    </BrowserRouter>
  );
}

export default App;