import { useEffect, useState/*, useCallback*/ } from 'react';
import splash from './ultilidades/splash'
import Cuenta from './Cuenta'

function Micuenta() {
  let initialized = false
  useEffect(() => {
    
    if(!initialized){
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div>
      <div className="fondo_titulos">
        <div className="max_width texto_titulo">
          <br/>Mi cuenta
        </div>
      </div>
      <div className="fondo_gris">
        <div className="max_width padding_width">
          <center>
            <h11>Editar mi cuenta</h11>
          </center>
          <Cuenta cual="micuenta"/>
        </div>
      </div>
    </div>
  );
}

export default Micuenta;
