import { useEffect, useState/*, useCallback*/ } from 'react';
import { Element, scroller } from 'react-scroll';
import { StickyContainer, Sticky } from 'react-sticky';
import Axios from 'axios';
import { Link/*, useHistory*/ } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from 'react-bootstrap/Modal';
//import Swal from 'sweetalert2'
import splash from './ultilidades/splash'
import Infografias from './ultilidades/Infografias'
import VerBlog from './ultilidades/Blog'
import { useSelector } from 'react-redux'
//import { miMenu, } from './../redux/menuRedux'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

/*
const alerta = (tipo,titulo) =>{
  Swal.fire({
    type: tipo,
    title: titulo
  });
}
*/

function Noticias() {
  const menuRedux = useSelector((state) => state.menu.value)
  //const [inicial, setInicial] = useState(0)
  //const [mostrar, setMostrar] = useState(6)
  const [modalBlog, setModalBlog] = useState({
    ver: false,
    id: 0,
  })

  const [cantidad, setCantidad] = useState(0)

  const [lista_blog, set_lista_blog] = useState([])
  const [cantidadTotal, setCantidadTotal] = useState(0)
  const listaTodas = (cantidad) => {
    window.H5_loading.show();
    var pasar = new URLSearchParams();
    pasar.append('_CLUSTER', 'clusterminerodehidalgo');

    pasar.append('inicial', cantidad);
    pasar.append('que', 'blog');
    pasar.append('seccion', menuRedux);

    Axios.post(`${window.ws}wsSelect.php`, pasar)
      .then(response => {
        set_lista_blog(response.data.lista_array)
        setCantidadTotal(response.data.total)
      })
      .catch(error => {
        console.log(error)
        //alert(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  let initialized = false
  useEffect(() => {
    listaTodas(0)
    if(!initialized){
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div>
      <div className="fondo_titulos">
        <div className="max_width texto_titulo">
          <br/>{menuRedux==='noticias' ? 'Eventos y Noticias' : 'Información Minera'}
        </div>
      </div>
      <div className="fondo_gris">
        <div className="max_width padding_width">
          {
            localStorage._ROL==='ADMIN_CLUSTER' ?
              <div className="flex_act_nueva">
                <div className="flex_nueva" onClick={() =>
                  setModalBlog({
                    ver: true,
                    id: 0,
                })}>
                  <div className="nueva">+</div>
                  <div className="l_nueva">Publicar</div>
                </div>
              </div>
            :
              null
          }
          <center>
            <Element name="recorrer"></Element>
            {
              menuRedux==='noticias' ?
                <div>
                  <h11>Todos los eventos y noticias</h11>
                  <h14>ENTÉRATE DE LO MÁS RELEVANTE EN EL SECTOR MINERO</h14>
                </div>
              :
                <div>
                  <h11>Información Minera</h11>
                  <h14>INFORMACIÓN IMPORTANTE DEL SECTOR MINERO</h14>
                </div>
            }
          </center>
          {
            lista_blog.length>0 ?
              <div>
                <div id="cl"><br/><br/></div>
                <StickyContainer className="flex_paginacion">
                  <div className="paginacion">
                    {
                      lista_blog.map((item, i) => {
                        let foto = ""
                        if(item.bl_foto){
                          foto = `${window.ws}documentos/blog/${item.bl_id}.${item.bl_foto}?v=${item.bl_v}`
                        }else{
                          foto = "img/fondo.svg"
                        }

                        let texto = item.bl_texto
                        if(item.bl_texto.length>150){
                          texto = item.bl_texto.substring(0,150)+"..."
                        }

                        let miFecha = new Date(`${item.bl_fecha.split(" ")[0]}T00:00`)
                        return(
                          <div className="not_sec" key={i}>
                            <Link to={`./nota/${item.bl_id}`} className="de_not_sec" data-aos="fade-right" data-aos-duration="500"><img src={foto} title={item.bl_titulo} alt={item.bl_titulo}/></Link>
                            <ordena data-aos="fade-left" data-aos-duration="500">
                              <Link to={`./nota/${item.bl_id}`} className="titulo_not_sec">{item.bl_titulo}</Link>
                              {
                                item.bl_tipo>0 ?
                                  <div className="fecha_not_sec"><b>{window._my_blog_categorias.filter(p => p.id===item.bl_tipo)[0].nombre}</b> | {`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</div>
                                :
                                  <div className="fecha_not_sec">{`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</div>
                              }
                              <div dangerouslySetInnerHTML={{ __html: texto }} className="descripcion_not_sec"/>
                            </ordena>
                          </div>
                        )
                      })
                    }
                    {/*rex {lista_blogTotal} redux {lista_blogCantidad}*/}
                    <div className="pa_flex_siguiente">
                      <div className="fleex_siguiente">
                        <div>
                          {
                            cantidad===0 ?
                              null
                            :
                              <button className="siguiente con_mar" onClick={() =>{
                                scroller.scrollTo('recorrer', {
                                  duration: 100,
                                  smooth: true,
                                  spy:true,
                                })
                                let numeros = cantidad-window._my_cantidad_blog
                                setCantidad(numeros)
                                listaTodas(numeros)
                              }}>
                                Anterior
                              </button>
                          }
                        </div>
                        <div>
                          {
                            (cantidad+window._my_cantidad_blog)>=cantidadTotal ?
                              null
                            :
                              <button className="siguiente" onClick={() =>{
                                scroller.scrollTo('recorrer', {
                                  duration: 100,
                                  smooth: true,
                                  spy:true,
                                })
                                let numeros = cantidad+window._my_cantidad_blog
                                setCantidad(numeros)
                                listaTodas(numeros)
                              }}>
                                Siguiente
                              </button>
                          }
                        </div>  
                      </div>
                      <div className="num_pag">Página {(Math.ceil(cantidad/window._my_cantidad_blog)+1)} de {Math.ceil(cantidadTotal/window._my_cantidad_blog)}</div>
                    </div>
                  </div>
                  <div className="publicidad">
                    <Sticky>
                      {({
                        style,
                        isSticky,
                        wasSticky,
                        distanceFromTop,
                        distanceFromBottom,
                        calculatedHeight
                      }) => (
                        <header style={style} className="secciones_publicidad">
                          <Infografias />
                        </header>
                      )}
                    </Sticky>
                  </div>
                </StickyContainer>
                <div className="secciones_publicidad quita_sec_pub">
                  <Infografias />
                </div>
              </div>
            :
             null
          }
        </div>
      </div>

      <Modal
        show={modalBlog.ver}
        size="lg"
        className="modal_registro"
        centered
        onHide={() => 
          setModalBlog({
            ver: false,
            id: 0,
          })
        }
        //style={{zIndex: index>0 ? 3 : null}}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Publicar en el blog
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VerBlog modal={modalBlog} setModal={setModalBlog} listaTodas={listaTodas}/>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Noticias;
