import { useEffect, useState/*, useCallback*/ } from 'react';
import splash from './ultilidades/splash'
import { Element, Link as Ir } from 'react-scroll';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Button from 'react-bootstrap/Button';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import Axios from 'axios';
import alerta from './ultilidades/alerta'
import cerrarSesion from './ultilidades/cerrar'

function Usuarios() {
  const [modal, setModal] = useState(false);
  const [inicial, setInicial] = useState(0)
  const [mostrar, setMostrar] = useState(25)
  const [cantidad, setCantidad] = useState(25)

  let initialized = false
  useEffect(() => {
    
    if(!initialized){
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div>
      <div className="fondo_titulos">
        <div className="max_width texto_titulo">
          <br/>Publicar
        </div>
      </div>
      <div className="fondo_gris">
        <div className="max_width padding_width">
          <center>
            <h11>Eventos y noticias</h11>
          </center>
          
        </div>
      </div>
    </div>
  );
}

export default Usuarios;
