const splash = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  window.H5_loading.show();
  setTimeout(() => {
    window.H5_loading.hide();
  }, 1000)
}

export default splash;