import { useEffect, useState/*, useCallback*/ } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2'
import splash from './ultilidades/splash'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const alerta = (tipo,titulo) =>{
  Swal.fire({
    type: tipo,
    title: titulo
  });
}

function Servicios() {
  let initialized = false
  useEffect(() => {
    
    if(!initialized){
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div>
      <div className="fondo_titulos">
        <div className="max_width texto_titulo">
          <br/>Servicios
        </div>
      </div>
      <div className="fondo_gris">
        <div className="max_width padding_width">
          <center>
            <h11>conoce</h11>
            <h12>Los servicios que ofrecemos</h12>
          </center>
          <div className="flex_between alinear">
            <div className="nosotros_texto" data-aos="fade-right">
              <h19><b>Vinculaciones con Instituciones Públicas y/o Privadas</b></h19>
              <div className="justificar">
                Acompañamiento para la regulación y promoción de la actividad minera y Fomentar el conocimiento geológico-minero en la Entidad.
              </div>
            </div>
            <div className="nosotros_img" data-aos="fade-left">
              <div className="box_imagen">
                <img src="img/fotos/foto4.jpg" title="Vinculaciones con Instituciones Públicas y/o Privadas" alt="Vinculaciones con Instituciones Públicas y/o Privadas"/>
              </div>
            </div>
          </div>
          <div className="flex_between alinear espa_bet">
            <div className="nosotros_img" data-aos="fade-right">
              <div className="box_imagen">
                <img src="img/fotos/slider_9.jpg" title="Vinculaciones con Instituciones Públicas y/o Privadas" alt="Vinculaciones con Instituciones Públicas y/o Privadas"/>
              </div>
            </div>
            <div className="nosotros_texto" data-aos="fade-left">
              <h19><b>Capacitaciones al sector minero</b></h19>
              <div className="justificar">
                Apoyo en la capacitación técnica para empresas mineras desarrollando habilidades en los diferentes temas:
              </div>
              <ul className="lista">
                <li>Seguridad en Minas a Cielo Abierto</li>
                <li>Optimización de Arranque con Maquinaria</li>
                <li>Seguridad en Minas NOM_023-2012 STPS</li>
                <li>Uso y Manejo de Explosivos a cielo abierto y subterráneo</li>
                <li>Taller de Ahorro y Uso Eficiente de Energía Eléctrica</li>
                <li>Uso y Aprovechamiento Eficaz de la Explotación de los Recursos Naturales</li>
                <li>Normatividad y Regulación Minera</li>
                <li>Minería Sustentable</li>
              </ul>
            </div>
          </div>
          <div className="flex_between alinear">
            <div className="nosotros_texto" data-aos="fade-right">
              <h19><b>Oferta laboral de los Entes del Clúster</b></h19>
              <div className="justificar">
                Vacantes que requieran los diferentes entes para el desarrollo de sus actividades en el Sector Minero.
              </div>
            </div>
            <div className="nosotros_img" data-aos="fade-left">
              <div className="box_imagen">
                <img src="img/fotos/slider_8.jpg" title="Vinculaciones con Instituciones Públicas y/o Privadas" alt="Vinculaciones con Instituciones Públicas y/o Privadas"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Servicios;
