const limpiarVariables = (c) => {
    c = c.replace(/[|<|,|>|\?|\/|:|;|"|'|\+|=]+/g, ""); //eslint-disable-line
    c = c.replace("gmial", "gmail");
    c = c.replace("hotmial", "hotmail");
    c = c.replace("mysql", "");
    c = c.replace("mongodb", "");
    c = c.replace(" update ", "");
    c = c.replace(" delete ", "");
    c = c.replace(" UPDATE ", "");
    c = c.replace(" DELETE ", "");
    return c;
}
export default limpiarVariables;