import Axios from 'axios';
const cerrarSesion = (msj) => {
  if(msj && msj>0){
    window.H5_loading.show();
    var pasar = new URLSearchParams();
    pasar.append('_USUARIO', localStorage.usuario);
    pasar.append('_ROL', localStorage._ROL);
    pasar.append('_Activa', localStorage._Activa);

    Axios.post(`${window.ws}wsError.php`, pasar)
      .then(response => {
        if(response.data.elError===2){
          console.log(response.data.mensaje)
        }
      })
      .catch(error => {
        //alerta('error', ''+error)
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  if(localStorage.usuario){
    localStorage.removeItem('usuario')
    localStorage.removeItem('_ROL')
    localStorage.removeItem('_Nombre')
    localStorage.removeItem('_Activa')
  }
  //history.push('login');
  if(msj && msj>0){
    window.location.href = window.rutas + "?mensaje=error";
  }else{
    window.location.href = window.rutas;
  }
}

export default cerrarSesion;