import { createSlice } from '@reduxjs/toolkit'

let link = 'noticias'
const ruta = window.location.href.split('/');
if(ruta[3]==='articulos'){
  link = 'articulos'
}

export const menuReducer = createSlice({
  name: 'menu',
  initialState: {
    value: link,
  },
  reducers: {
    miMenu: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { miMenu, } = menuReducer.actions
export default menuReducer.reducer