import { createSlice } from '@reduxjs/toolkit'

export const personalReducer = createSlice({
  name: 'personal',
  initialState: {
    value: [],
  },
  reducers: {
    miPersonal: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { miPersonal, } = personalReducer.actions
export default personalReducer.reducer