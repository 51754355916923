import { configureStore } from '@reduxjs/toolkit'

import menuReducer from './menuRedux'
import styleReducer from './styleRedux'
import personalReducer from './personalRedux'

export default configureStore({
  reducer: {
    menu: menuReducer,
    style: styleReducer,
    personal: personalReducer,
  },
})