import { createSlice } from '@reduxjs/toolkit'

export const styleReducer = createSlice({
  name: 'style',
  initialState: {
    value: 'no_ver_menu',
  },
  reducers: {
    miStyle: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { miStyle, } = styleReducer.actions
export default styleReducer.reducer