function Infografias() {
  return (
    <div>
      {
        window.lista_infografia.length>0 ?
          <div className="blog_publicidad">
            <div className="titulos_blog">
              Infografías
            </div>
            {
              window.lista_infografia.map((item, i) => {
                let miFecha = new Date(`${item.fecha.slice(0, 10)}T00:00`)
                return(
                  <div key={i} className="my_galeria_blog my_galeria_con_blog">
                    <img src={item.foto} title={item.titulo} alt={item.titulo}/>
                    <div className="hover_galeria">
                      <div className="cajas_galeria">
                        {item.titulo}
                        <div className="linea_galeria"/>
                        <h18>{`${window.meses[miFecha.getMonth()]}, ${miFecha.getFullYear()}`}</h18>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        :
          null
      }
    </div>
  );
}

export default Infografias;
